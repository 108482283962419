import React from "react";
import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import UploadButton from "../../shared/components/UploadButton";
import {
  GetRoadFiles,
  SelectRoad,
  UploadRoadFile,
  DeleteRoadFile,
  GetRoadInfor,
} from "../../services/actions/RoadAction";
import {
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";

import "./style.scss";
import { CircularProgress, TablePagination } from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment-timezone";
import Map from "./map";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import i18n from "../../localization/i18n";

const RoadInformation = ({
  roadFiles,
  selectedRow,
  setSelectedRow,
  GetRoadGeoJson,
  DeleteRoadFile,
  loading,
  UploadRoadFile,
  selectedRoad,
}) => {
  return (
    <div className="road-information-container">
      <div>
        {HasPermissions(MenuType.RoadInfoList, PermissionType.Upload) && (
          <UploadButton
            title={i18n.t("label_upload_road_info")}
            placeholder={i18n.t("label_upload_road_info_plceholder")}
            uploading={loading}
            handleUploadFile={UploadRoadFile}
          />
        )}

        <div
          style={{ display: loading ? "block" : "none", textAlign: "center" }}
        >
          <CircularProgress />
        </div>
        <MaterialTable
          title={i18n.t("label_geo_file")}
          columns={[
            { title: i18n.t("table_title_owner"), field: "owner.userName" },
            { title: i18n.t("table_title_filename"), field: "fileName" },
            { title: i18n.t("table_title_type"), field: "type" },
            {
              title: i18n.t("table_title_created_date"),
              field: "createdDate",
              type: "datetime",
              render: (rowData) => (
                <>
                  {moment(rowData.createdDate, "YYYY-MM-DD")
                    .local()
                    .format("MM/DD/YYYY")}
                </>
              ),
            },
            {
              title: i18n.t("table_title_number_road"),
              field: "numberOfRoad",
              type: "numeric",
            },
          ]}
          data={JSON.parse(JSON.stringify(roadFiles))}
          editable={
            HasPermissions(MenuType.RoadInfoList, PermissionType.Delete)
              ? {
                  onRowDelete: (oldData) => DeleteRoadFile(oldData.id),
                }
              : {}
          }
          onRowClick={(evt, selected) => {
            if (selectedRow && selectedRow.id === selected.id) {
              setSelectedRow(null);
              GetRoadGeoJson(null);
            } else {
              setSelectedRow(selected);
              GetRoadGeoJson(selected);
            }
          }}
          options={{
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow && selectedRow.tableData.id === rowData.tableData.id
                  ? MAIN_COLOR
                  : "#FFF",
            }),
          }}
          style={{ display: !loading ? "block" : "none" }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} rowsPerPageOptions={[]} />
            ),
          }}
        />
      </div>
      {selectedRow && selectedRoad && <Map data={selectedRoad} />}
    </div>
  );
};

export default compose(
  withTranslation("translations"),
  withState("selectedRow", "setSelectedRow", null),
  connect(
    (state) => ({
      loading: state.road.loading,
      roadFiles: state.road.roadFiles,
      selectedRoad: state.road.selectedRoad,
    }),
    (dispatch) => ({
      GetRoadFiles: () => dispatch(GetRoadFiles()),
      GetRoadGeoJson: (file) => dispatch(GetRoadInfor(file)),
      UploadRoadFile: (file) => dispatch(UploadRoadFile(file)),
      DeleteRoadFile: (id) => dispatch(DeleteRoadFile(id)),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.GetRoadFiles();
    },
  })
)(RoadInformation);
