import React from "react";
import { Field, reduxForm, change, Form } from "redux-form";
import TextField from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Button,
  Grid,
  CircularProgress,
  Box,
  Snackbar,
  TablePagination,
} from "@material-ui/core";
import * as Yup from "yup";
import validator from "../../shared/utils/validator";
import { get } from "lodash";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";

import "./style.scss";
import {
  UpdateSetting,
  ClearSettingData,
  GetSystemSetting,
} from "../../services/actions/SettingAction";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserS3List from "./UserS3List";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Alert } from "@material-ui/lab";
import { Trans } from "react-i18next";
import { Cancel, Save } from "@material-ui/icons";
import moment from "moment";
import MaterialTable from "material-table";
import i18n from "../../localization/i18n";
import { GetLoginLogs } from "../../services/actions/SystemAction";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  renderInput,
  system,
  setCopied,
  copyName,
  ...custom
}) => (
  <FormControl
    variant="outlined"
    className="field"
    error={error ? true : false}
  >
    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
    {renderInput ? (
      renderInput({ input, custom })
    ) : (
      <TextField
        placeholder={label}
        error={touched && invalid}
        {...input}
        {...custom}
        disabled
      />
    )}
    <CopyToClipboard
      text={system ? system[copyName] : ""}
      onCopy={() => setCopied(true)}
    >
      <Button
        className="copy-btn"
        variant="text"
        disableElevation
        startIcon={<FileCopyIcon color="primary" />}
        size="small"
      >
        Copy
      </Button>
    </CopyToClipboard>
    <FormHelperText>{error}</FormHelperText>
  </FormControl>
);

const Settings = ({
  loading,
  saving,
  handleSubmit,
  onSubmit,
  pristine,
  reset,
  submitting,
  system,
  copied,
  setCopied,
  onUpdateNoticeSubmit,
}) => {
  return (
    <div className="setting-form">
      <h1>Settings</h1>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="heading">S3 Storage Setting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            {loading ? (
              <CircularProgress />
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={12}>
                    <div>
                      <Field
                        name="apiKeyFix"
                        copyName="apiKey"
                        component={(props) =>
                          renderTextField({
                            ...props,
                            system: system,
                            setCopied: setCopied,
                          })
                        }
                        label="Api Key"
                        labelWidth={80}
                        disabled={saving}
                      />
                      <Field
                        name="secretKeyFix"
                        copyName="secretKey"
                        component={(props) =>
                          renderTextField({
                            ...props,
                            system: system,
                            setCopied: setCopied,
                          })
                        }
                        label="Secrect Key"
                        labelWidth={80}
                        disabled={saving}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
            <h1>Manage user's S3 storage</h1>
            <UserS3List />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="update-notice-setting-content"
          id="update-notice-setting"
        >
          <Typography className="heading">Logs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            {loading ? (
              <CircularProgress />
            ) : (
              <MaterialTable
                title={i18n.t("label_login_logs")}
                columns={[
                  {
                    title: i18n.t("table_action"),
                    field: "action",
                  },
                  { title: i18n.t("table_detail"), field: "other" },
                  {
                    title: "Action date",
                    field: "createdDate",
                    type: "datetime",
                    render: (rowData) => (
                      <>
                        {moment(rowData.createdDate, "YYYY-MM-DD")
                          .local()
                          .format("DD/MM/YYYY")}
                      </>
                    ),
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    return GetLoginLogs(query)
                      .then((res) => {
                        resolve({
                          data: res.data,
                          page: query.page,
                          totalCount: res.totalCount,
                        });
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  })
                }
                options={{
                  maxBodyHeight: "calc(50vh - 100px)",
                  sorting: false,
                  draggable: false,
                }}
                style={{ display: !loading ? "block" : "none" }}
                components={{
                  Pagination: (props) => (
                    <TablePagination {...props} rowsPerPageOptions={[]} />
                  ),
                }}
              />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="update-notice-setting-content"
          id="update-notice-setting"
        >
          <Typography className="heading">Update Notice Setting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            {loading ? (
              <CircularProgress />
            ) : (
              <Form onSubmit={handleSubmit(onUpdateNoticeSubmit)}>
                <Grid container>
                  <Grid item md={12}>
                    <div>
                      <Field
                        name="updateNote"
                        component={({
                          label,
                          input,
                          meta: { touched, invalid, error },
                          renderInput,
                          system,
                          setCopied,
                          copyName,
                          ...custom
                        }) => (
                          <FormControl
                            variant="outlined"
                            className="field"
                            error={error ? true : false}
                          >
                            <InputLabel>{label}</InputLabel>
                            <TextField
                              placeholder={label}
                              error={touched && invalid}
                              value={input.value}
                              onChange={input.onChange}
                              autoFocus={true}
                              {...custom}
                            />
                            <FormHelperText>{error}</FormHelperText>
                          </FormControl>
                        )}
                        label="Update note"
                        labelWidth={100}
                        disabled={saving}
                      />
                    </div>
                    <div className="actions">
                      <Button
                        type="submit"
                        size="small"
                        startIcon={saving ? null : <Save />}
                        disabled={pristine || submitting || saving}
                        className="main-btn"
                      >
                        {saving ? (
                          <CircularProgress color="white" size={22} />
                        ) : (
                          <Trans>save</Trans>
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={copied}
        autoHideDuration={500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setCopied(false)}
      >
        <Alert severity="success">Copied!</Alert>
      </Snackbar>
    </div>
  );
};

const validateSchema = Yup.object().shape({
  apiKey: Yup.string().required("Api Key is required"),
  secretKey: Yup.string().required("Secret Key is required"),
});

export default compose(
  reduxForm({
    form: "SettingForm",
    asyncValidate: validator(validateSchema),
  }),
  withState("isEdit", "setIsEdit", false),
  withState("settingState", "setSettingState", null),
  withState("stateSaving", "setStateSaving", false),
  withState("copied", "setCopied", false),
  connect(
    (state) => ({
      loading: state.setting.loading,
      saving: state.setting.saving,
      isAdmin: state.auth.isAdmin,
      system: state.setting.system,
      success: state.system.success,
    }),
    (dispatch) => ({
      getSetting: () => dispatch(GetSystemSetting()),
      updateSetting: (data) => dispatch(UpdateSetting(data)),
      clearSetting: () => dispatch(ClearSettingData()),
      changeFieldValue: (field, value) =>
        dispatch(change("SettingForm", field, value)),
    })
  ),
  withHandlers({
    onSubmit:
      ({ match, updateSetting, setStateSaving }) =>
      (values) => {
        setStateSaving(true);
        updateSetting({ id: 1, ...values });
      },
    onUpdateNoticeSubmit:
      ({ match, updateSetting, setStateSaving }) =>
      (values) => {
        setStateSaving(true);
        updateSetting({ id: 1, ...values });
      },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getSetting();
    },
    componentDidUpdate() {
      const {
        settingState,
        system,
        changeFieldValue,
        saving,
        stateSaving,
        success,
      } = this.props;
      if (!settingState && system) {
        this.props.setSettingState(system);

        Object.keys(system).map((k) => {
          changeFieldValue(k, system[k]);
        });
      }

      if (
        stateSaving &&
        !saving.saving &&
        !get(this.props, "match.params.id") &&
        success
      ) {
        window.location.href = "/settings";
      }
    },
    componentWillUnmount() {
      // this.props.clearSetting();
    },
  })
)(Settings);
