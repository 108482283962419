import React, { useRef } from "react";
import { Map as LeafletMap } from "react-leaflet";

import { compose, withState } from "recompose";
import { GeoJSONFillable, Patterns } from "react-leaflet-geojson-patterns";
import FullscreenControl from 'react-leaflet-fullscreen';
import { get } from 'lodash';
import { geolocated } from "react-geolocated";
import { MAIN_COLOR, BaseMapTile } from "../../shared/utils/constant";

const Map = ({
  data,
  coords
}) => {
  const mapEl = useRef(null);
  const position = get(data, "features[0].geometry.coordinates[0]", null);
  const center = position ? position.length > 3 ? [position[0][1], position[0][0]] : [position[1], position[0]] : [get(coords, "latitude", 30.55435), get(coords, "longitude", -91.03677)]
  const mappZoom = 14;
  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={mappZoom}
      style={{ height: `calc(100% - 64px)` }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      {data && <GeoJSONFillable
        data={data}
        style={feature => ({
          color: MAIN_COLOR,
          fillPattern: Patterns.CirclePattern({
            x: 7,
            y: 7,
            radius: 5,
            fill: true,
            width: 15,
            height: 15,
            color: MAIN_COLOR,
            key: "circle"
          })
        })}
      ></GeoJSONFillable>}
    </LeafletMap>
  );
};


export default compose(
  withState("zoom", "setZoom", 16),
  withState("center", "setCenter", [39.94792279, -79.99223758]),
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })
)(Map);
