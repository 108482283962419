import React from "react";
import UploadForm from "./UploadForm";

import "./style.scss";
import FilterCard from "./FilterCard";
import DeteriorationRank from "./DeteriorationRank";
import { AccessPeriodCard } from "./AccessPeriodCard";

const GisMapDetail = ({ closeSurveyedDetail, mapEl }) => {
  return (
    <div className="gis-map-detail">
      <div>
        <FilterCard mapEl={mapEl} closeSurveyedDetail={closeSurveyedDetail} />
        <DeteriorationRank closeSurveyedDetail={closeSurveyedDetail} />
        <UploadForm />
        <div className="upload-container"></div>
        <AccessPeriodCard />
      </div>
    </div>
  );
};

export default GisMapDetail;
