import React from "react";
import { connect } from "react-redux";

import {
  Menu,
  MenuItem,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Tooltip,
  Select,
} from "@material-ui/core";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AccountCircle,
  Menu as MenuIcon,
  MoreVert as MoreIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Map as MapIcon,
  LineStyle as RoadIcon,
  ZoomOutMap as FullscreenIcon,
  SwapCalls as SurveyedIcon,
  RecentActors as UsersIcon,
  LinearScale as SurfaceIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { withTranslation, Trans } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import i18ninit from "../localization/i18n";
import logo from "../logo.svg";
import clsx from "clsx";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { logout } from "../services/actions/AuthAction";
import screenfull from "screenfull";

const NavMenu = ({
  selectedNav,
  authenticated,
  handleRedirect,
  logout,
  isAdmin,
  setSelectedNav,
  i18n,
  user,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuOnClick = (menu) => {
    handleDrawerClose();
    setSelectedNav(menu);
    handleRedirect(menu);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleRedirect("profile");
        }}
      >
        <Trans>profile</Trans>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          logout();
          handleRedirect("login");
        }}
      >
        <Trans>logout</Trans>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>
          <Trans>profile</Trans>
        </p>
      </MenuItem>
    </Menu>
  );

  const localizationSelect = (
    <Select
      IconComponent={() => <></>}
      disableUnderline
      onChange={(e) => i18n.changeLanguage(e.target.value)}
      value={i18ninit.languages[0]}
      renderValue={(value) => (
        <ReactCountryFlag code={value === "en" ? "us" : "jp"} svg />
      )}
    >
      <MenuItem value="en">
        <ReactCountryFlag code="us" svg />
        <div style={{ paddingLeft: 10 }}>English</div>
      </MenuItem>
      <MenuItem value="jp">
        <ReactCountryFlag code="jp" svg />
        <div style={{ paddingLeft: 10 }}>Japanese</div>
      </MenuItem>
    </Select>
  );

  return (
    <nav className="top-nav">
      {!authenticated ? (
        <>
          <div className="logo">
            <img src={logo} alt="logo"></img>
          </div>
          <h1>
            <Trans>app_title</Trans>
          </h1>
          {/* {localizationSelect} */}
        </>
      ) : (
        <div className={classes.grow}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                <Trans>app_title</Trans>
              </Typography>
              <div className={classes.grow} />
              {/* <div className={classes.sectionDesktop}>{localizationSelect}</div> */}
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => {
                    screenfull.toggle();
                  }}
                  color="inherit"
                >
                  <FullscreenIcon />
                </IconButton>
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              <Tooltip title={<Trans>gis</Trans>}>
                <ListItem
                  button
                  onClick={() => handleMenuOnClick("gis")}
                  className={`${selectedNav === "gis" ? "menu-selected" : ""}`}
                >
                  <ListItemIcon>
                    <MapIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Trans>gis</Trans>} />
                </ListItem>
              </Tooltip>
              <Tooltip title={<Trans>road_surveyed_list</Trans>}>
                <ListItem
                  button
                  onClick={() => handleMenuOnClick("surveyed")}
                  className={`${
                    selectedNav === "surveyed" ? "menu-selected" : ""
                  }`}
                >
                  <ListItemIcon>
                    <SurveyedIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Trans>road_surveyed_list</Trans>} />
                </ListItem>
              </Tooltip>
              {isAdmin && (
                <Tooltip title={<Trans>road_information_list</Trans>}>
                  <ListItem
                    button
                    onClick={() => handleMenuOnClick("road")}
                    className={`${
                      selectedNav === "road" ? "menu-selected" : ""
                    }`}
                  >
                    <ListItemIcon>
                      <RoadIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Trans>road_information_list</Trans>}
                    />
                  </ListItem>
                </Tooltip>
              )}
              {isAdmin && (
                <Tooltip title={<Trans>manage_users</Trans>}>
                  <ListItem
                    button
                    onClick={() => handleMenuOnClick("users")}
                    className={`${
                      selectedNav === "users" ? "menu-selected" : ""
                    }`}
                  >
                    <ListItemIcon>
                      <UsersIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Trans>manage_users</Trans>} />
                  </ListItem>
                </Tooltip>
              )}
              <Tooltip title={<Trans>manage_surface_condition</Trans>}>
                <ListItem
                  button
                  onClick={() => handleMenuOnClick("surfaces")}
                  className={`${
                    selectedNav === "surfaces" ? "menu-selected" : ""
                  }`}
                >
                  <ListItemIcon>
                    <SurfaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Trans>manage_surface_condition</Trans>}
                  />
                </ListItem>
              </Tooltip>
              {isAdmin && (
                <Tooltip title={<Trans>settings</Trans>}>
                  <ListItem
                    button
                    onClick={() => handleMenuOnClick("settings")}
                    className={`${
                      selectedNav === "settings" ? "menu-selected" : ""
                    }`}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Trans>settings</Trans>} />
                  </ListItem>
                </Tooltip>
              )}
            </List>
          </Drawer>
          {renderMobileMenu}
          {renderMenu}
        </div>
      )}
    </nav>
  );
};
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default compose(
  withTranslation("translations"),
  withState("selectedNav", "setSelectedNav", "gis"),
  withState("selectedLang", "setSelectedLang", "en"),
  connect(
    (state) => ({
      authenticated: state.auth.authenticated,
      isAdmin: state.auth.isAdmin,
      user: state.auth.user,
    }),
    (dispatch) => ({
      logout: () => dispatch(logout()),
    })
  ),
  withHandlers({
    handleRedirect:
      ({ history }) =>
      (routeName) => {
        history.push(`/${routeName}`);
      },
  }),
  lifecycle({
    componentDidMount() {
      const currentNav = window.location.href.split("/").pop();
      this.props.setSelectedNav(currentNav || "gis");
    },
  })
)(NavMenu);
