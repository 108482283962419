import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { HasPermissions } from "../../../shared/utils/objectExtensions";
import { MenuType, PermissionType } from "../../../shared/utils/constant";

export const AccessPeriodCard = () => {
  const loggedUser = JSON.parse(sessionStorage.getItem("userLogged"));
  const showAdvanceNotice =
    loggedUser?.accessPeriod && loggedUser?.noticeDays
      ? moment()
          .add(loggedUser.noticeDays, "days")
          .isAfter(moment(loggedUser.accessPeriod))
      : false;

  return (
    <Box className="access-period-container">
      {showAdvanceNotice && (
        <Card
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
          }}
        >
          <CardContent style={{ paddingBottom: "16px" }}>
            <Typography color="error" style={{ fontWeight: "bold" }}>
              {loggedUser?.noticeMessage || (
                <>
                  GIS access will EXPIRE soon. Please contact{" "}
                  <a href="https://www.cnexco.com/contact">NHSA</a> for further
                  details.
                </>
              )}
            </Typography>
          </CardContent>
        </Card>
      )}
      {loggedUser?.accessPeriod &&
        HasPermissions(MenuType.GIS, PermissionType.AccessPeriod) && (
          <div
            style={{
              padding: 10,
              margin: "10px 0",
              border: "1px solid chocolate",
              borderRadius: "4px",
              backgroundColor: "white",
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "chocolate", fontWeight: "bold" }}
            >
              GIS access will expire on{" "}
              {moment(loggedUser?.accessPeriod, "YYYY-MM-DD").format("DD/MMM/yyyy")}
            </Typography>
          </div>
        )}
    </Box>
  );
};
