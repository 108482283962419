import React from "react";
import { Field, reduxForm, change, Form, formValueSelector } from "redux-form";
import TextField from "@material-ui/core/OutlinedInput";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Button,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  List as ListIcon,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { Trans } from "react-i18next";
import * as Yup from "yup";
import validator from "../../shared/utils/validator";
import { get } from "lodash";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import "./style.scss";
import {
  MenuType,
  PermissionType,
  UserRole,
} from "../../shared/utils/constant";
import {
  GetUserDetail,
  UpdateUserDetail,
  ClearUserData,
} from "../../services/actions/UserAction";
import moment from "moment";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  customHelperText,
  ...custom
}) => {
  return (
    <FormControl
      variant="outlined"
      className="field"
      error={error ? true : false}
    >
      <InputLabel {...(custom.InputLabelProps || {})}>{label}</InputLabel>
      <TextField
        placeholder={label}
        error={touched && invalid}
        {...input}
        {...custom}
        inputProps={{
          autocomplete: "new-password",
        }}
      />
      {customHelperText ? customHelperText : <></>}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

const renderPermissionCheckbox = ({ input, label, disabled, roles }) => {
  const { value, onChange } = input;

  const onChanged = (menuType, permissionType, checked) => {
    var result = [];
    if (value) result = JSON.parse(JSON.stringify(value));

    if (result.find((x) => x.id === menuType)) {
      result.map((x) => {
        if (x.id === menuType) {
          const newPermissionTypes = (x.permissionTypes || []).filter(
            (x) => x !== permissionType.name
          );
          x.permissionTypes = checked
            ? [
                ...newPermissionTypes,
                ...(permissionType.name === PermissionType.Edit.name
                  ? [PermissionType.View.name, permissionType.name]
                  : [permissionType.name]),
              ]
            : newPermissionTypes;
        }
        return x;
      });
    } else {
      result.push({
        id: menuType,
        permissionTypes: checked
          ? [
              ...(permissionType.name === PermissionType.Edit.name
                ? [PermissionType.View.name, permissionType.name]
                : [permissionType.name]),
            ]
          : [],
      });
    }

    onChange(result);
  };

  const getValue = (menuType, permissionType) => {
    if (value) {
      const permission = value.find((x) => x.id === menuType);
      if (permission) {
        return get(permission, "permissionTypes", []).includes(
          permissionType.name
        );
      }
    }

    return false;
  };

  return (
    <>
      <h2>Permissions</h2>
      {Object.keys(MenuType)
        .map((k) => ({ key: k, ...MenuType[k] }))
        .map((item, idx) => {
          return roles == 2 &&
            (item.value === MenuType.RoadInfoList.value ||
              item.value === MenuType.ManageUsers.value ||
              item.value === MenuType.Settings.value) ? null : (
            <div key={idx} className="section">
              <p>
                <b>{item.label}</b>
              </p>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={getValue(item.value, PermissionType.View)}
                    onChange={(e) =>
                      onChanged(
                        item.value,
                        PermissionType.View,
                        e.target.checked
                      )
                    }
                  />
                }
                label="View"
              />
              {item.key !== "Dashboard" && item.key !== "Settings" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={getValue(item.value, PermissionType.Add)}
                      onChange={(e) =>
                        onChanged(
                          item.value,
                          PermissionType.Add,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Add"
                />
              )}
              {item.key !== "Dashboard" &&
                item.key !== "SurveyedList" &&
                item.key !== "RoadInfoList" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={getValue(item.value, PermissionType.Edit)}
                        onChange={(e) =>
                          onChanged(
                            item.value,
                            PermissionType.Edit,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Edit"
                  />
                )}
              {item.key !== "Dashboard" && item.key !== "Settings" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={getValue(item.value, PermissionType.Delete)}
                      onChange={(e) =>
                        onChanged(
                          item.value,
                          PermissionType.Delete,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Delete"
                />
              )}
              {item.key !== "Dashboard" &&
                item.key !== "ManageUsers" &&
                item.key !== "SurfaceCondition" &&
                item.key !== "Settings" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={getValue(item.value, PermissionType.Upload)}
                        onChange={(e) =>
                          onChanged(
                            item.value,
                            PermissionType.Upload,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Upload"
                  />
                )}
              {item.key !== "ManageUsers" &&
                item.key !== "SurfaceCondition" &&
                item.key !== "Settings" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={getValue(item.value, PermissionType.Download)}
                        onChange={(e) =>
                          onChanged(
                            item.value,
                            PermissionType.Download,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Download"
                  />
                )}
              {item.key === "GIS" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={getValue(
                        item.value,
                        PermissionType.AccessPeriod
                      )}
                      onChange={(e) =>
                        onChanged(
                          item.value,
                          PermissionType.AccessPeriod,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Access Period"
                />
              )}
              {item.key === "GIS" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={getValue(
                        item.value,
                        PermissionType.SwitchImages
                      )}
                      onChange={(e) =>
                        onChanged(
                          item.value,
                          PermissionType.SwitchImages,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Switch Images"
                />
              )}
            </div>
          );
        })}
    </>
  );
};

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  changeFieldValue,
  ...custom
}) => {
  const customOnChange = (e) => {
    const newValue = e.target.value;
    if (newValue == UserRole.User) {
      changeFieldValue("permissions", [
        { id: 3, permissionTypes: [1, 2, 4, 5, 6] },
        { id: 2, permissionTypes: [6, 1, 2, 1, 3, 4, 5] },
      ]);
    } else {
      changeFieldValue("permissions", [
        { id: 4, permissionTypes: [1, 2, 1, 3, 4, 5, 6] },
        { id: 3, permissionTypes: [1, 2, 4, 5, 6] },
        { id: 2, permissionTypes: [6, 1, 2, 1, 3, 4, 5] },
        { id: 1, permissionTypes: [1, 6] },
        { id: 5, permissionTypes: [1, 2, 1, 3, 4] },
      ]);
    }
    input.onChange(newValue);
  };
  return (
    <FormControl error={touched && error} className="field" variant="outlined">
      <InputLabel shrink htmlFor={input.name}>
        {label}
      </InputLabel>
      <Select native {...input} {...custom} onChange={customOnChange}>
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
};

const UserForm = ({
  loading,
  saving,
  handleSubmit,
  onSubmit,
  pristine,
  reset,
  submitting,
  isAdmin,
  showPassword,
  setShowPassword,
  changeFieldValue,
  rolesValue,
}) => {
  let history = useHistory();

  return (
    <div className="user-form">
      <h1>User form</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "calc(100vh - 142px)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <Grid container spacing={10}>
            <Grid item sm={6} xs={12}>
              <div>
                <Field
                  name="firstName"
                  component={renderTextField}
                  label="First Name"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="lastName"
                  component={renderTextField}
                  label="Last Name"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="email"
                  component={renderTextField}
                  label="Email"
                  labelWidth={40}
                  disabled={saving}
                />
              </div>
              <div>
                <Field
                  name="userName"
                  component={renderTextField}
                  label="Username"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="password"
                  component={renderTextField}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  labelWidth={80}
                  disabled={saving}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Field
                  name="passwordConfirm"
                  component={renderTextField}
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  labelWidth={135}
                  disabled={saving}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              {isAdmin && (
                <div>
                  <Field
                    name="roles"
                    component={renderSelectField}
                    label="User Role"
                    labelWidth={75}
                    changeFieldValue={changeFieldValue}
                    disabled={saving}
                  >
                    <option value={2}>User</option>
                    <option value={1}>Admin</option>
                  </Field>
                </div>
              )}
              <Field
                name="notes"
                component={renderTextField}
                label="Notes"
                multiline
                rows="4"
                labelWidth={45}
                disabled={saving}
              />
              <Field
                name="accessPeriod"
                component={renderTextField}
                label="Accesss Period"
                type="date"
                labelWidth={110}
                disabled={saving}
                InputLabelProps={{ shrink: true }}
              />
              <Field
                name="noticeDays"
                component={renderTextField}
                label="Advance notice days"
                type="number"
                labelWidth={150}
                disabled={saving}
              />
              <Field
                name="noticeMessage"
                component={renderTextField}
                label="Notice Message"
                labelWidth={120}
                disabled={saving}
                customHelperText={
                  <FormHelperText>
                    Keep it empty for the default message
                  </FormHelperText>
                }
              />

              <Field
                name="expirationMessage"
                component={renderTextField}
                label="Expiration Message"
                labelWidth={145}
                disabled={saving}
                customHelperText={
                  <FormHelperText>
                    Keep it empty for the default message
                  </FormHelperText>
                }
              />
            </Grid>
            <Grid item sm={6} xs={12} className="right">
              <Field
                name="permissions"
                component={renderPermissionCheckbox}
                disabled={saving}
                roles={rolesValue}
              />
            </Grid>
          </Grid>
          <div className="actions">
            <Button
              type="submit"
              size="small"
              startIcon={saving ? null : <SaveIcon />}
              disabled={pristine || submitting || saving}
              className="main-btn"
            >
              {saving ? (
                <CircularProgress color="white" size={22} />
              ) : (
                <Trans>save</Trans>
              )}
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<CancelIcon />}
              disabled={pristine || submitting || saving}
              onClick={reset}
            >
              <Trans>cancel</Trans>
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<ListIcon />}
              onClick={() => history.push("/users")}
            >
              <Trans>back_to_list</Trans>
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  userName: Yup.string().required("UserName is required"),
  password: (window.location.pathname.includes("new")
    ? Yup.string().required("Password is required")
    : Yup.string().nullable()
  )
    .min(8, "Passwords must be at least 8 characters long.")
    .matches(/[a-z]+/, "Passwords must contain a lowercase letter.")
    .matches(/[A-Z]+/, "Passwords must contain an uppercase letter.")
    .matches(/[0-9]+/, "Passwords must contain a number."),
  passwordConfirm: (window.location.pathname.includes("new")
    ? Yup.string().required("Confirm Password is required")
    : Yup.string().nullable()
  ).test("passwords-match", "Passwords must match.", function (value) {
    return (!this.parent.password && !value) || this.parent.password === value;
  }),
});

const selector = formValueSelector("UserForm");
export default compose(
  reduxForm({
    form: "UserForm",
    asyncValidate: validator(validateSchema),
  }),
  withState("isEdit", "setIsEdit", false),
  withState("userState", "setUserState", null),
  withState("showPassword", "setShowPassword", false),
  withState("stateSaving", "setStateSaving", false),
  connect(
    (state) => ({
      loading: state.user.loading,
      saving: state.user.saving,
      isAdmin: state.auth.isAdmin,
      userDetail: state.user.userDetail,
      success: state.system.success,
      rolesValue: selector(state, "roles"),
    }),
    (dispatch) => ({
      getUserDetail: (id) => dispatch(GetUserDetail(id)),
      updateUserDetail: (id, data) => dispatch(UpdateUserDetail(id, data)),
      clearUserDetail: () => dispatch(ClearUserData()),
      changeFieldValue: (field, value) =>
        dispatch(change("UserForm", field, value)),
    })
  ),
  withHandlers({
    onSubmit:
      ({ match, updateUserDetail, setStateSaving }) =>
      (values) => {
        setStateSaving(true);
        updateUserDetail(get(match, "params.id"), {
          ...values,
          roles: [{ id: values.roles }],
        });
      },
  }),
  lifecycle({
    componentDidMount() {
      const userId = get(this.props, "match.params.id");
      if (userId) {
        this.props.setIsEdit(true);
        this.props.getUserDetail(userId);
      } else {
        this.props.changeFieldValue("permissions", [
          { id: 3, permissionTypes: [1, 2, 4, 5, 6] },
          { id: 2, permissionTypes: [6, 1, 2, 1, 3, 4, 5] },
        ]);
        this.props.changeFieldValue("roles", 2);
      }
    },
    componentDidUpdate() {
      const {
        userState,
        userDetail,
        changeFieldValue,
        saving,
        stateSaving,
        success,
      } = this.props;
      if (!userState && userDetail) {
        this.props.setUserState(userDetail);

        Object.keys(userDetail).map((k) => {
          changeFieldValue(
            k,
            k === "roles"
              ? userDetail[k][0].id
              : k === "accessPeriod" && userDetail[k]
              ? moment(userDetail[k], "YYYY-MM-DD").format("YYYY-MM-DD")
              : userDetail[k]
          );
        });
      }

      if (
        stateSaving &&
        !saving.saving &&
        !get(this.props, "match.params.id") &&
        success
      ) {
        window.location.href = "/users";
      }
    },
    componentWillUnmount() {
      this.props.clearUserDetail();
    },
  })
)(UserForm);
