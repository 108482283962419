import { ApiEndpoint } from "../../env.config";
import { authFetch } from "../../shared/utils/fetchUtils";

export const SystemTypes = {
  ERROR: "ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  CLEAR_WARNING: "CLEAR_WARNING",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",
  START_PROCESS: "START_PROCESS",
  UPDATE_PROCESS: "UPDATE_PROCESS",
  STOP_PROCESS: "STOP_PROCESS",
  PERMISSION_REQUIRED_SHOW: "PERMISSION_REQUIRED_SHOW",
  PERMISSION_REQUIRED_CLOSE: "PERMISSION_REQUIRED_CLOSE",
};

export const SetError = (errorObj) => ({
  type: SystemTypes.ERROR,
  error: `${errorObj.message}`,
});

export const ClearError = () => ({
  type: SystemTypes.CLEAR_ERROR,
});

export const SetWarning = (message) => ({
  type: SystemTypes.WARNING,
  warning: `${message}`,
});

export const SetSuccess = (message) => ({
  type: SystemTypes.SUCCESS,
  success: `${message}`,
});

export const ClearWarning = () => ({
  type: SystemTypes.CLEAR_WARNING,
});

export const ClearSuccess = () => ({
  type: SystemTypes.CLEAR_SUCCESS,
});

export const StartProcessing = (numberOfProcess) => ({
  type: SystemTypes.START_PROCESS,
  number: numberOfProcess,
});

export const UpdateProcessing = () => ({
  type: SystemTypes.UPDATE_PROCESS,
});

export const StopProcessing = () => ({
  type: SystemTypes.STOP_PROCESS,
});

export const ShowPermissionRequired = () => ({
  type: SystemTypes.PERMISSION_REQUIRED_SHOW,
});

export const ClosePermissionRequired = () => ({
  type: SystemTypes.PERMISSION_REQUIRED_CLOSE,
});

export function GetLoginLogs(data) {
  return authFetch(`${ApiEndpoint}/User/logs`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}
