import Immutable from "seamless-immutable";
import { SurfaceTypes } from "../actions/SurfaceAction";

const initialState = Immutable({
  loading: false,
  saving: false,
  errors: null,
  surfaces: [],
  surfaceDetail: null,
  selectedUser: null,
});

const SurfaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SurfaceTypes.FETCHING:
      return state.merge({
        loading: true,
      });
    case SurfaceTypes.SAVING:
      return state.merge({
        saving: true,
      });
    case SurfaceTypes.SAVED:
      return state.merge({
        saving: false,
      });
    case SurfaceTypes.DELETED:
      return state.merge({
        saving: false,
        surfaces: state.surfaces.filter((x) => x.id !== action.id),
      });
    case SurfaceTypes.GET_SUCCESS:
      return state.merge({
        loading: false,
        surfaces: action.data,
      });
    case SurfaceTypes.GET_DETAIL_SUCCESS:
      return state.merge({
        loading: false,
        surfaceDetail: action.data,
      });
    case SurfaceTypes.CLEAR_DATA:
      return state.merge({
        surfaceDetail: null,
      });
    case SurfaceTypes.ERROR:
      return state.merge({
        loading: false,
      });
    case SurfaceTypes.SET_USER:
      return state.merge({
        selectedUser: action.userId,
      });
    default:
      return state;
  }
};

export default SurfaceReducer;
