import Immutable from "seamless-immutable";
import { SettingTypes } from "../actions/SettingAction";

const initialState = Immutable({
  loading: false,
  saving: false,
  errors: null,
  settings: null,
  system: null,
  files: [],
});

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SettingTypes.FETCHING:
      return state.merge({
        loading: true,
      });
    case SettingTypes.SAVING:
      return state.merge({
        saving: true,
      });
    case SettingTypes.SAVED:
      return state.merge({
        saving: false,
      });
    case SettingTypes.GET_SUCCESS:
      return state.merge({
        loading: false,
        settings: action.data,
      });
    case SettingTypes.GET_SYSTEM_SUCCESS:
      return state.merge({
        loading: false,
        system: action.data,
      });
    case SettingTypes.GET_USER_FILE_SUCCESS:
      return state.merge({
        loading: false,
        files: action.data,
      });
    case SettingTypes.ERROR:
      return state.merge({
        loading: false,
      });
    default:
      return state;
  }
};

export default SettingReducer;
