import React from 'react'
import { withTranslation, Trans } from 'react-i18next';
import { compose } from "recompose";

const PermissionRequired = () => {
    return (
        <div className="permission-required-content">
            <h1><Trans>permission_required</Trans></h1>
            <p><b><Trans>permission_required_text</Trans></b></p>
        </div>
    )
}

export default compose(
    withTranslation("translations"),
  )(PermissionRequired);