import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core';

import './Input.scss'

const Input = ({
  inputRef,
  label,
  value,
  onTextChanged,
  placeholder,
  icon,
  iconPosition = 'end',
  inputClassName,
  variant = 'filled',
  margin = 'normal',
  type = 'text',
  error,
  startAdornment,
  onKeyPress }) => {
  return (
    <>
      <TextField
        inputRef={inputRef}
        margin={margin}
        className={`group-input ${inputClassName} ${error && 'error'}`}
        variant={variant}
        type={type}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (onTextChanged) {
            onTextChanged(e.target.value);
          }
        }}
        InputProps={{
          startAdornment: startAdornment && <InputAdornment position="start">{startAdornment()}</InputAdornment>,
          endAdornment: (
            icon && <InputAdornment position={iconPosition}>
              {icon()}
            </InputAdornment>
          ),
        }}
        onKeyPress={onKeyPress}
      />
      {error && <div className="error-text">{error}</div>}
    </>
  )
}

export default Input
