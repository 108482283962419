import Immutable from 'seamless-immutable';
import { SystemTypes } from '../actions/SystemAction';

const initialState = Immutable({
  error: '',
  warning: '',
  success: '',
  requiredPermissionModal: false,
  processing: null,
  processingTaskDone: null,
  processingTaskNumber: null
});

const SystemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SystemTypes.ERROR:
      return state.merge({
        error: action.error,
      })
    case SystemTypes.CLEAR_ERROR:
      return state.merge({
        error: ''
      })
    case SystemTypes.WARNING:
      return state.merge({
        warning: action.warning,
      })
    case SystemTypes.SUCCESS:
      return state.merge({
        success: action.success,
      })
    case SystemTypes.CLEAR_WARNING:
      return state.merge({
        warning: ''
      })
    case SystemTypes.CLEAR_SUCCESS:
      return state.merge({
        success: ''
      })
    case SystemTypes.START_PROCESS:
      return state.merge({
        processingTaskNumber: action.number,
        processing: 1,
      })
    case SystemTypes.UPDATE_PROCESS:
      const numberTaskDone = state.processingTaskDone + 1;
      return state.merge({
        processing: Math.round(numberTaskDone / state.processingTaskNumber * 100),
        processingTaskDone: numberTaskDone
      })
    case SystemTypes.STOP_PROCESS:
      return state.merge({
        processing: null,
        processingTaskDone: null,
        processingTaskNumber: null
      })
    case SystemTypes.PERMISSION_REQUIRED_SHOW:
      return state.merge({
        requiredPermissionModal: true
      })
    case SystemTypes.PERMISSION_REQUIRED_CLOSE:
      return state.merge({
        requiredPermissionModal: false
      })
    default:
      return state
  }
}

export default SystemReducer