import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Bar, Line, Pie } from "react-chartjs-2";
import { barChartOptions } from "./bar.data";
import { lineChartOptions } from "./line.data";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { get } from "lodash";

import "./style.scss";
import {
  GetDashboardReport,
  GetDistressReport,
} from "../../services/actions/RoadAction";
import { compose, lifecycle, withState } from "recompose";
import Map from "./map";
import { withTranslation, Trans } from "react-i18next";
import { GetSurfaces } from "../../services/actions/SurfaceAction";

Chart.plugins.register(ChartDataLabels);

export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "right",
    labels: {
      boxWidth: 10,
      fontSize: 10,
      fontColor: "white",
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) =>
        `${data["labels"][tooltipItem["index"]]}: ${
          data["datasets"][0]["data"][tooltipItem["index"]]
        }%`,
    },
  },
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value ? `${value} %` : "";
      },
      color: "white",
      font: {
        size: 15,
        weigth: "bold",
      },
      align: "end",
    },
  },
};

const Dashboard = ({
  repairedReport,
  ratingReport,
  damageReport,
  deteriorationReport,
  repairedReportLoading,
  ratingReportLoading,
  damageReportLoading,
  deteriorationReportLoading,
  roadNameSelected,
  setRoadNameSelected,
  roadListOpen,
  setRoadListOpen,
  surfaces,
}) => {
  return (
    <Container className="container dashboard" maxWidth="lg">
      <div className="wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card style={{ height: "100%" }}>
              <CardHeader
                className="card-header"
                title={<Trans>dashboard_total_length</Trans>}
              />
              <CardContent className="bar-chart-bg">
                {repairedReportLoading ? (
                  <CircularProgress />
                ) : repairedReport &&
                  repairedReport.data &&
                  repairedReport.data.length > 0 ? (
                  <Bar
                    data={{
                      labels: repairedReport.label,
                      datasets: [
                        {
                          label: "Total length",
                          data: repairedReport.data,
                          backgroundColor: "#4c6164",
                          borderColor: "#8ab6c1",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={barChartOptions}
                  />
                ) : (
                  <p style={{ color: "white" }}>{<Trans>no_data</Trans>}</p>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card style={{ height: "100%" }}>
              <CardHeader
                className="card-header"
                title={<Trans>dashboard_mean_pavement_distress</Trans>}
              />
              <CardContent className="line-chart-bg">
                {ratingReportLoading ? (
                  <CircularProgress />
                ) : ratingReport &&
                  ratingReport.data &&
                  ratingReport.data.length > 0 ? (
                  <Line
                    data={{
                      labels: ratingReport.label,
                      datasets: [
                        {
                          data: ratingReport.data,
                          backgroundColor: "transparent",
                          borderColor: "#8ab6c1",
                          borderWidth: 4,
                        },
                      ],
                    }}
                    options={lineChartOptions}
                  />
                ) : (
                  <p style={{ color: "white" }}>{<Trans>no_data</Trans>}</p>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <Card className="damage-distribution-container">
              <CardHeader
                className="card-header"
                title={<Trans>dashboard_pavement_distress_map</Trans>}
              />
              <CardContent>
                {damageReportLoading ? (
                  <CircularProgress />
                ) : (
                  damageReport && <Map data={damageReport} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card>
              <CardHeader
                className="card-header"
                title={<Trans>dashboard_pavement_distress_distribution</Trans>}
              />
              <CardContent className="pie-chart">
                {get(deteriorationReport, "roadNames", []).length > 0 && (
                  <div className="route-selection-dropdown">
                    ROUTE:
                    <Autocomplete
                      options={get(deteriorationReport, "roadNames")}
                      style={{ width: 200, height: 30 }}
                      getOptionLabel={(option) => option}
                      onOpen={() => setRoadListOpen(true)}
                      onClose={() => setRoadListOpen(false)}
                      onChange={(e, value) =>
                        setRoadNameSelected(value || "All Road")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={!roadListOpen && roadNameSelected}
                          variant="standard"
                          fullWidth
                          style={{ color: "white" }}
                        />
                      )}
                    />
                  </div>
                )}
                {deteriorationReportLoading ? (
                  <CircularProgress />
                ) : (
                  deteriorationReport &&
                  (deteriorationReport.data.length !==
                  deteriorationReport.data.filter((x) => x === 0).length ? (
                    <div style={{ width: 300, height: 300, margin: "0 auto" }}>
                      <Pie
                        data={{
                          labels: deteriorationReport.label,
                          datasets: [
                            {
                              data: deteriorationReport.data,
                              backgroundColor: surfaces
                                ? surfaces.map((x) => x.color)
                                : [],
                            },
                          ],
                        }}
                        options={pieChartOptions}
                      />
                    </div>
                  ) : (
                    <p style={{ color: "white" }}>{<Trans>no_data</Trans>}</p>
                  ))
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default compose(
  withTranslation("translations"),
  withState("roadNameSelected", "setRoadNameSelected", "All road"),
  withState("roadListOpen", "setRoadListOpen", false),
  connect(
    (state) => ({
      loading: state.road.loading,
      repairedReport: state.road.repairedReport,
      ratingReport: state.road.ratingReport,
      damageReport: state.road.damageReport,
      deteriorationReport: state.road.deteriorationReport,
      repairedReportLoading: state.road.repairedReportLoading,
      ratingReportLoading: state.road.ratingReportLoading,
      damageReportLoading: state.road.damageReportLoading,
      deteriorationReportLoading: state.road.deteriorationReportLoading,
      surfaces: state.surface.surfaces,
    }),
    (dispatch) => ({
      getData: () => dispatch(GetDashboardReport()),
      getDistressReport: (roadName) => dispatch(GetDistressReport(roadName)),
      getSurfaceConditions: () => dispatch(GetSurfaces()),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.getData();
      this.props.getSurfaceConditions();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.roadNameSelected !== this.props.roadNameSelected) {
        this.props.getDistressReport(this.props.roadNameSelected);
      }
    },
  })
)(Dashboard);
