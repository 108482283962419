import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Popover,
} from "@material-ui/core";

import { CloudUpload } from "@material-ui/icons";
import { compose, withState } from "recompose";
import UploadForm from "../Gis/LeftContent/UploadForm";

const Upload = ({ title, noImageUpload, userId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button variant="contained" className="upload-btn" onClick={handleClick}>
        <CloudUpload /> {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <UploadForm noImageUpload={noImageUpload} userId={userId} />
      </Popover>
    </>
  );
};

export default compose(withState("uploadFile", "setUploadFile", null))(Upload);
