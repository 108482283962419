import { authFetch } from "../../shared/utils/fetchUtils";
import {
  SetError,
  SetSuccess,
  StartProcessing,
  StopProcessing,
} from "./SystemAction";
import { ApiEndpoint } from "../../env.config";
import { SelectUserFilter } from "./RoadAction";

export const UserTypes = {
  FETCHING: "FETCHING",
  SAVING: "SAVING",
  SAVED: "SAVED",
  DELETED: "DELETED",
  GET_SUCCESS: "GET_SUCCESS",
  GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS",
  ERROR: "USER_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_DATA: "CLEAR_DATA",
};

const Fetching = () => ({
  type: UserTypes.FETCHING,
});

const Saving = () => ({
  type: UserTypes.SAVING,
});

const Saved = () => ({
  type: UserTypes.SAVED,
});

const Deleted = (id) => ({
  type: UserTypes.DELETED,
  id,
});

const UserError = () => ({
  type: UserTypes.ERROR,
});

const GetUsersSuccess = (data) => ({
  type: UserTypes.GET_SUCCESS,
  data,
});

const GetUserDetailSuccess = (data) => ({
  type: UserTypes.GET_DETAIL_SUCCESS,
  data,
});

const SetSystemError = SetError;

export const ClearUserData = () => ({
  type: UserTypes.CLEAR_DATA,
});

export const ResetAuth = () => ({
  type: UserTypes.RESET_AUTH,
});

export function GetUsers() {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/user`, { method: "GET" })
      .then((json) => {
        dispatch(GetUsersSuccess(json));

        if (window.location.href.toLowerCase().includes("gis")) {
          if (json && json[json.length - 1]?.id) {
            dispatch(SelectUserFilter(json[json.length - 1].id));
          }
        }
      })
      .catch((errObj) => {
        dispatch(UserError());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function GetUserDetail(id) {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/user/${id}`, { method: "GET" })
      .then((json) => dispatch(GetUserDetailSuccess(json)))
      .catch((errObj) => {
        dispatch(UserError());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function UpdateUserDetail(id, data) {
  return function (dispatch) {
    dispatch(Saving());

    return authFetch(`${ApiEndpoint}/user${id ? "" : "/register"}`, {
      method: id ? "PUT" : "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((_json) => {
        dispatch(Saved());
        dispatch(SetSuccess("Save successfully!"));
      })
      .catch((errObj) => {
        dispatch(Saved());
        dispatch(UserError());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function DeleteUser(id) {
  return function (dispatch) {
    dispatch(Saving());
    dispatch(StartProcessing(2));
    return authFetch(`${ApiEndpoint}/user/${id}`, { method: "DELETE" })
      .then((_json) => {
        dispatch(StopProcessing());
        dispatch(Deleted(id));
        dispatch(SetSuccess("Delete successfully!"));
      })
      .catch((errObj) => {
        dispatch(StopProcessing());
        dispatch(Saved());
        dispatch(UserError());
        dispatch(SetSystemError(errObj));
      });
  };
}
