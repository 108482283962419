import React, { useRef, useEffect } from 'react'
import Particles from 'react-particles-js'
import { connect } from 'react-redux'

import Input from '../../shared/components/Input'
import { IconButton, Card, CardContent, CardActions } from '@material-ui/core'
import { Visibility, VisibilityOff, AccountCircle } from '@material-ui/icons';
import { MAIN_COLOR } from '../../shared/utils/constant'
import Button from '../../shared/components/Button'

import './style.scss'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { login } from '../../services/actions/AuthAction'
import { withFormik } from 'formik'
import { Trans, withTranslation } from 'react-i18next'

const Login = (props) => {
  return (
    <div className="login-container">
      <LoginForm {...props} />
      <Particles className="login-container" params={particleSetting} />
    </div>
  )
}

const LoginForm = ({ authenticating, values, errors, handleLogin, showPassword, setShowPassword, setFieldValue }) => {
  const userNameEl = useRef(null);

  useEffect(() => {
    if (userNameEl) {
      userNameEl.current.focus();
    }
    document.body.style = "overflow: hidden;";
  }, []);

  return (
    <Card className="login-card">
      <CardContent className="login-form">
        <h1><Trans>user_login</Trans></h1>
        <Input
          inputRef={userNameEl}
          label={<Trans>user_id</Trans>}
          value={values.username}
          onTextChanged={value => setFieldValue("username", value)}
          error={errors.username}
          icon={() => <IconButton
            edge="end"
            aria-label="toggle password visibility"
            disabled={true}
          >
            <AccountCircle style={{ color: MAIN_COLOR }} />
          </IconButton>}
          onKeyPress={(e) => { if (e.key === "Enter") handleLogin() }}
        />
        <Input
          label={<Trans>user_password</Trans>}
          value={values.password}
          onTextChanged={value => setFieldValue("password", value)}
          error={errors.password}
          type={!showPassword ? 'password' : "text"}
          icon={() => <IconButton
            edge="end"
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={e => e.preventDefault()}
          >
            {!showPassword ? <VisibilityOff style={{ color: MAIN_COLOR }} /> : <Visibility style={{ color: MAIN_COLOR }} />}
          </IconButton>}
          onKeyPress={(e) => { if (e.key === "Enter") handleLogin() }} />
      </CardContent>
      <CardActions className="form-action">
        <Button
          title={<Trans>login</Trans>}
          loading={authenticating}
          disabled={Object.keys(errors).length > 0}
          onClick={() => handleLogin()} />
      </CardActions>
    </Card>
  )
}

export default compose(
  withTranslation("translations"),
  withState('showPassword', 'setShowPassword', false),
  withFormik({
    mapPropsToValues: () => ({ username: '', password: '' }),
    validate: values => {
      const errors = {};

      if (!values.username) {
        errors.username = 'Username is required';
      }
      if (!values.password) {
        errors.password = 'Password is required';
      }

      return errors;
    }
  }),
  connect(
    state => ({
      authenticating: state.auth.authenticating,
      authenticated: state.auth.authenticated,
    }),
    dispatch => ({
      login: (username, password) => dispatch(login(username, password)),
    })
  ),
  withHandlers({
    handleLogin: ({ values, history, login }) => () => {
      login(values.username, values.password);
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.authenticated) this.props.history.push('/gis');
    },
    componentDidUpdate() {
      if (this.props.authenticated) this.props.history.push('/gis');
    }
  })
)(Login)

const particleSetting = {
  "particles": {
    "number": {
      "value": 80,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": ["#0d47a1", "#E28020", "#eeff41", "#ff9800", "#f44336"]
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#b6b2b2"
      }
    },
    "opacity": {
      "value": 0.5211089197812949,
      "random": false,
      "anim": {
        "enable": true,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 8,
      "random": true,
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#E28020",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 1,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "bounce",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": { "enable": true },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 10,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
};
