import React, { useEffect } from "react";
import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import MaterialTable from "material-table";

import {
  DeleteRoadSurveydFile,
  GetRoadSurveyed,
  ClearSurveyedData,
  GetRoadSurveyedFilesPromise,
} from "../../services/actions/RoadAction";
import {
  DANGER_COLOR,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import UploadButton from "./UploadButton";

import "./style.scss";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TablePagination,
} from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment-timezone";
import Map from "./map";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import Swal from "sweetalert2";
import i18n from "../../localization/i18n";
import { authFileFetch } from "../../shared/utils/fetchUtils";
import { ApiEndpoint } from "../../env.config";
import Loading from "../../shared/components/Loading";
import FileSaver from "file-saver";
import {
  GetSurfaces,
  SetSelectedUser,
} from "../../services/actions/SurfaceAction";
import { GetUsers } from "../../services/actions/UserAction";

const RoadSurveyed = ({
  loading,
  selectedRow,
  setSelectedRow,
  DeleteSurveyedFile,
  selectedDatas,
  setSelectedDatas,
  GetSurveyedData,
  ClearSurveyedData,
  setDownloading,
  downloading,
  loggedUser,
  isAdmin,
  users,
  selectedUser,
  setSelectedUser,
  getSurfaces,
  ...props
}) => {
  const tableRef = React.createRef();
  const [triggerReload, setTriggerReload] = React.useState(0);
  var surveyedAllocated = [];
  selectedDatas.map((x) => surveyedAllocated.push(...x.surveyedAllocated));
  const shouldShowMap =
    selectedRow && surveyedAllocated && surveyedAllocated.length > 0;

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }

    getSurfaces(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (!props.uploading) {
      tableRef.current.onQueryChange();
    }
  }, [props.uploading]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [triggerReload]);

  return (
    <div className="road-surveyed-container">
      {(downloading || loading) && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {isAdmin && (
        <Box marginBottom={2}>
          <InputLabel
            htmlFor="user"
            style={{ color: "black", paddingBottom: "5px" }}
          >
            <Trans>user</Trans>
          </InputLabel>
          <FormControl className="field" variant="outlined">
            <Select
              native
              name="user"
              value={selectedUser}
              onChange={(e) => {
                setSelectedUser(e.target.value);
              }}
            >
              {loggedUser && (
                <option key={loggedUser.id} value={loggedUser.id}>
                  All
                </option>
              )}
              {users.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.userName} - {x.firstName} {x.lastNameF}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <div>
        {HasPermissions(MenuType.SurveyedList, PermissionType.Upload) ? (
          <UploadButton
            title={i18n.t("label_upload_surveyed")}
            placeholder={i18n.t("label_upload_surveyed_placeholder")}
            uploading={loading}
          />
        ) : (
          <></>
        )}

        <MaterialTable
          tableRef={tableRef}
          title={i18n.t("label_surveyed_file")}
          columns={[
            {
              title: i18n.t("table_title_owner"),
              field: "owner.userName",
            },
            { title: i18n.t("table_title_filename"), field: "fileName" },
            {
              title: i18n.t("table_title_created_date"),
              field: "createdOn",
              type: "datetime",
              render: (rowData) => (
                <>
                  {moment(rowData.createdOn, "YYYY-MM-DD")
                    .local()
                    .format("DD/MM/YYYY")}
                </>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              setDownloading(false);
              return GetRoadSurveyedFilesPromise({
                ...query,
                userId: selectedUser,
              })
                .then((res) => {
                  resolve({
                    data: res.data,
                    page: query.page,
                    totalCount: res.totalCount,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            })
          }
          actions={[
            HasPermissions(MenuType.SurveyedList, PermissionType.Edit) && {
              icon: "download",
              tooltip: "Download",
              iconProps: { color: "primary" },
              onClick: async (_e, rowData) => {
                setDownloading(true);
                try {
                  const response = await authFileFetch(
                    `${ApiEndpoint}/Road/surveyed/download?fileUrl=${rowData.filePath}`,
                    { method: "GET" }
                  );
                  setDownloading(false);
                  var blob = await response.blob();
                  var filename = rowData.filePath.replace(/^.*[\\\/]/, "");
                  filename = filename.substring(0, filename.indexOf(".csv"));
                  FileSaver.saveAs(blob, `${filename}.csv`);
                } catch (error) {
                  console.error(error);
                  setDownloading(false);
                }
              },
            },
            HasPermissions(MenuType.SurveyedList, PermissionType.Delete) && {
              icon: "delete",
              tooltip: "Delete User",
              iconProps: { style: { color: DANGER_COLOR } },
              onClick: (_e, rowData) =>
                DeleteSurveyedFile(rowData.id, () => {
                  setTriggerReload(triggerReload + 1);
                }),
            },
          ].filter((x) => x)}
          onRowClick={(evt, selected) => {
            if (selectedRow.find((x) => x.id === selected.id)) {
              setSelectedRow(selectedRow.filter((x) => x.id !== selected.id));
              setSelectedDatas(
                selectedDatas.filter((x) => x.id !== selected.id)
              );
              ClearSurveyedData();
            } else {
              setSelectedRow([...selectedRow, selected]);
              GetSurveyedData(selected.id, selected.owner.id);
            }
          }}
          options={{
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow &&
                selectedRow.find((x) => x.tableData.id === rowData.tableData.id)
                  ? MAIN_COLOR
                  : "#FFF",
            }),
            maxBodyHeight: "calc(50vh - 100px)",
            sorting: false,
            draggable: false,
          }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} rowsPerPageOptions={[]} />
            ),
          }}
        />
      </div>
      {shouldShowMap && <Map data={surveyedAllocated} />}
    </div>
  );
};

export default compose(
  withTranslation("translations"),
  withState("selectedRow", "setSelectedRow", []),
  withState("selectedDatas", "setSelectedDatas", []),
  withState("downloading", "setDownloading", false),
  connect(
    (state) => ({
      loading: state.road.loading,
      uploading: state.road.uploading,
      roadSurveyedFiles: state.road.roadSurveyedFiles,
      surveyedAllocated: state.road.surveyedAllocated,
      isAdmin: state.auth.isAdmin,
      loggedUser: state.auth.user,
      users: state.user.users,
      selectedUser: state.surface.selectedUser,
    }),
    (dispatch) => ({
      GetSurveyedData: (fileId, userId) =>
        dispatch(GetRoadSurveyed(fileId, userId)),
      ClearSurveyedData: () => dispatch(ClearSurveyedData()),
      DeleteSurveyedFile: (id, callback = null) => {
        Swal.fire({
          title: "Are you sure you want to delete this item?",
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.value) {
            dispatch(DeleteRoadSurveydFile(id, callback));
          }
        });
      },
      getUsers: () => dispatch(GetUsers()),
      setSelectedUser: (userId) => dispatch(SetSelectedUser(userId)),
      getSurfaces: (userId) => dispatch(GetSurfaces(userId)),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.ClearSurveyedData();
      this.props.getUsers();
      this.props.getSurfaces();
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.surveyedAllocated &&
        JSON.stringify(prevProps.surveyedAllocated) !==
          JSON.stringify(this.props.surveyedAllocated) &&
        this.props.selectedRow.length > this.props.selectedDatas.length
      ) {
        this.props.setSelectedDatas([
          {
            id: this.props.selectedRow[this.props.selectedRow.length - 1].id,
            surveyedAllocated: this.props.surveyedAllocated,
          },
          ...this.props.selectedDatas,
        ]);
      }

      if (prevProps.uploading && !this.props.uploading) {
        this.props.ClearSurveyedData();
      }
    },
  })
)(RoadSurveyed);
