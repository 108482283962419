import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import Papa from "papaparse";
import { CircularProgress, TextField } from "@material-ui/core";
import moment from "moment";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { UploadSurveyedData } from "../../../services/actions/RoadAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "250px",
  },
  radioGroup: {},
  formControl: {
    margin: theme.spacing(1),
    width: "30%",
  },
}));

const FieldsMappingDialog = ({
  handleClose,
  saveSuccess,
  open,
  files,
  UploadSurveyedData,
  userId,
}) => {
  const classes = useStyles();
  const [fileSelected, setFileSelected] = React.useState(0);
  const [processedData, setProcessedData] = React.useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);

  const handleFileChange = (_event, newValue) => {
    setFileSelected(newValue);
  };

  const setFileType = (value) => {
    const currentData = [...processedData];
    currentData[fileSelected].fileType = value;
    setProcessedData(currentData);
  };

  const setSurveyYear = (value) => {
    const currentData = [...processedData];
    currentData[fileSelected].SurveyYear = value;
    setProcessedData(currentData);
  };

  const handleAddDP = () => {
    const currentData = [...processedData];
    const countDp = Object.keys(
      currentData[fileSelected].mappingFields.DP
    ).length;
    currentData[fileSelected].mappingFields.DP[`Y${countDp + 1}`] = "";
    setProcessedData(currentData);
  };

  const handleRemoeDP = (name) => {
    const currentData = [...processedData];
    delete currentData[fileSelected].mappingFields.DP[name];
    setProcessedData(currentData);
  };

  const handleFieldChange = (name, value) => {
    const currentData = [...processedData];
    currentData[fileSelected].mappingFields[name] = value;
    currentData[fileSelected].errors = null;
    setProcessedData(currentData);
  };

  const handleDPFieldChange = (name, value) => {
    const currentData = [...processedData];
    currentData[fileSelected].mappingFields.DP[name] = value;
    setProcessedData(currentData);
  };

  const handleSubmitData = () => {
    // Validate data
    const currentData = [...processedData];
    let hasError = false;
    currentData.forEach((item) => {
      var errors = {};
      if (!item.SurveyYear) {
        errors["SurveyYear"] = "Survey Year is required";
        hasError = true;
      }
      Object.keys(item.mappingFields).forEach((key) => {
        if (
          !item.mappingFields[key] && [
            "Latitude",
            "Longitude",
            "Distress",
            "RoadName",
          ]
        ) {
          errors[key] = `${key} is required`;
          hasError = true;
        }
      });

      item.errors = errors;
    });

    if (!hasError) {
      var submitObj = [];
      currentData.forEach((item) => {
        submitObj.push({
          file: item.file,
          surveyType: item.fileType,
          surveyYear: item.SurveyYear,
          userId: userId,
          mappedFields: JSON.stringify({
            ...item.mappingFields,
            fileType: item.fileType,
            surveyYear: item.SurveyYear,
          }),
          maxDpYear:
            item.fileType === "2" && item.mappingFields["DP"]
              ? Number(item.SurveyYear) +
                Object.keys(item.mappingFields["DP"]).length
              : null,
          predictedYears:
            item.fileType === "2"
              ? Object.keys(item.mappingFields["DP"]).map(
                  (_k, idx) => Number(item.SurveyYear) + idx + 1
                )
              : null,
          parsedData: item.parsedData.map((x) => {
            return {
              SourceVideo: item.mappingFields["SourceVideo"]
                ? x[item.mappingFields["SourceVideo"]]
                : "",
              Latitude: item.mappingFields["Latitude"]
                ? x[item.mappingFields["Latitude"]]
                : "",
              Longitude: item.mappingFields["Longitude"]
                ? x[item.mappingFields["Longitude"]]
                : "",
              PicPathDetected: item.mappingFields["PicPath_Detected"]
                ? x[item.mappingFields["PicPath_Detected"]]
                : "",
              PicPathOriginal: item.mappingFields["PicPath_Original"]
                ? x[item.mappingFields["PicPath_Original"]]
                : "",
              VideoDate: item.mappingFields["VideoDate"]
                ? x[item.mappingFields["VideoDate"]]
                : "",
              DefectTime: item.mappingFields["DefectTime"]
                ? x[item.mappingFields["DefectTime"]]
                : "",
              Distress: item.mappingFields["Distress"]
                ? x[item.mappingFields["Distress"]]
                : "",
              RoadName: item.mappingFields["RoadName"]
                ? x[item.mappingFields["RoadName"]]
                : "",
              DP: item.mappingFields["DP"]
                ? Object.keys(item.mappingFields["DP"]).map(
                    (y) => x[item.mappingFields["DP"][y]]
                  )
                : null,
            };
          }),
        });
      });

      UploadSurveyedData(submitObj, () => {
        setIsOpenConfirm(false);
        saveSuccess();
      });
    } else {
      setProcessedData(currentData);
    }
  };

  useEffect(() => {
    if (files) {
      var result = [];

      Promise.all(
        files.map(
          (file) =>
            new Promise((resolve, reject) =>
              Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: resolve,
                error: reject,
              })
            )
        )
      )
        .then((results) => {
          results.forEach((parseData, idx) => {
            let headers = Object.keys(parseData.data[0]);
            let hasDP = false;
            let currentObj = {
              SourceVideo: "",
              Latitude: "",
              Longitude: "",
              PicPath_Detected: "",
              PicPath_Original: "",
              VideoDate: "",
              DefectTime: "",
              Distress: "",
              RoadName: "",
              DP: {
                Y1: "",
                Y2: "",
                Y3: "",
              },
            };
            Object.keys(currentObj).map((k) => {
              if (k === "DP") {
                Object.keys(currentObj.DP).map((dpKey) => {
                  const dpHeaderName = headers.find(
                    (h) => h.toLowerCase() === `DP-${dpKey}`.toLowerCase()
                  );
                  if (dpHeaderName) {
                    hasDP = true;
                  }
                  currentObj.DP[dpKey] = dpHeaderName || "";
                });
              } else {
                const headerName = headers.find(
                  (h) => h.toLowerCase() === k.toLowerCase()
                );
                currentObj[k] = headerName || "";
              }
            });

            var surveyYear = parseData.data[0].VideoDate
              ? moment(parseData.data[0].VideoDate).year()
              : moment().year();
            if (isNaN(surveyYear)) {
              surveyYear = moment(
                parseData.data[0].VideoDate,
                "DD/MM/YYYY"
              ).year();

              if (!isNaN(surveyYear)) {
                parseData.data = parseData.data.map((x) => {
                  var test = moment(x.VideoDate).year();
                  if (isNaN(test)) {
                    x.VideoDate = moment(x.VideoDate, "DD/MM/YYYY").format(
                      "MM/DD/YYYY"
                    );
                  }
                  return x;
                });
              }
            }

            result.push({
              file: files[idx],
              SurveyYear: surveyYear,
              parsedData: parseData.data,
              fileType: hasDP ? "2" : "0",
              fieldList: headers,
              mappingFields: currentObj,
            });
          });

          setProcessedData(result);
        })
        .catch((error) => console.log("Something went wrong: ", error));
    }
  }, [files]);

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} disableBackdropClick={true}>
        <DialogTitle style={{ backgroundColor: "#E28020", color: "white" }}>
          Fields mapping
        </DialogTitle>
        <DialogContent
          dividers
          style={{ textAlign: processedData?.length ? "" : "center" }}
        >
          {processedData?.length ? (
            <div className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={fileSelected}
                onChange={handleFileChange}
                className={classes.tabs}
              >
                {files ? (
                  files.map((x, idx) => {
                    const hasError =
                      processedData[idx]?.errors &&
                      !isEmpty(processedData[idx]?.errors)
                        ? true
                        : false;
                    return (
                      <Tab
                        key={idx}
                        label={x.name}
                        {...a11yProps(idx)}
                        style={{
                          textTransform: "none",
                          color: hasError ? "red" : "inherit",
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </Tabs>
              {processedData?.length ? (
                processedData.map((item, idx) => {
                  return (
                    <TabPanel
                      key={idx}
                      value={fileSelected}
                      index={idx}
                      className={classes.radioGroup}
                    >
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue={"0"}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                        value={item.fileType}
                        onChange={(e, newValue) => {
                          setFileType(newValue);
                        }}
                      >
                        <FormControlLabel
                          value={"0"}
                          control={<Radio color="primary" />}
                          label="SPM-PASER"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={"1"}
                          control={<Radio color="primary" />}
                          label="SPM-PCI"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={"2"}
                          control={<Radio color="primary" />}
                          label="SPM-PCI + DP"
                          labelPlacement="end"
                        />
                        <TextField
                          label="Survey Year"
                          style={{ marginBottom: "20px" }}
                          value={item.SurveyYear}
                          onChange={(e) => setSurveyYear(e.target.value)}
                          error={item.errors?.SurveyYear ? true : false}
                          helperText={item.errors?.SurveyYear}
                        ></TextField>
                      </RadioGroup>
                      <div className="fields-mapping-container">
                        {Object.keys(item.mappingFields).map((key, idx) => {
                          const error = item.errors ? item.errors[key] : "";
                          return key !== "DP" ? (
                            <FormControl
                              key={idx}
                              className={classes.formControl}
                              error={error ? true : false}
                            >
                              <InputLabel
                                shrink={true}
                                className="header-selector"
                              >
                                {key}
                              </InputLabel>
                              <Select
                                onChange={(e) => {
                                  handleFieldChange(key, e.target.value);
                                }}
                                value={item.mappingFields[key]}
                              >
                                {item.fieldList.map((x, idx) => (
                                  <MenuItem key={idx} value={x}>
                                    {x}
                                  </MenuItem>
                                ))}
                              </Select>
                              {error ? (
                                <FormHelperText>{error}</FormHelperText>
                              ) : (
                                <></>
                              )}
                            </FormControl>
                          ) : (
                            <React.Fragment key={idx}></React.Fragment>
                          );
                        })}
                        {item.fileType === "2" ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <DPInput
                              classes={classes}
                              list={
                                item.mappingFields.DP
                                  ? Object.keys(item.mappingFields.DP).map(
                                      (k) => ({
                                        id: k,
                                        value: item.mappingFields.DP[k],
                                      })
                                    )
                                  : []
                              }
                              mappingFields={item.mappingFields}
                              fieldList={item.fieldList}
                              handleFieldChange={handleDPFieldChange}
                              handleAdd={handleAddDP}
                              handleRemove={handleRemoeDP}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </TabPanel>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          ) : (
            <CircularProgress color="primary" />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              // Validate data
              const currentData = [...processedData];
              let hasError = false;
              currentData.forEach((item) => {
                var errors = {};
                if (!item.SurveyYear) {
                  errors["SurveyYear"] = "Survey Year is required";
                  hasError = true;
                }
                Object.keys(item.mappingFields).forEach((key) => {
                  if (
                    !item.mappingFields[key] && [
                      "Latitude",
                      "Longitude",
                      "Distress",
                      "RoadName",
                    ]
                  ) {
                    errors[key] = `${key} is required`;
                    hasError = true;
                  }
                });

                item.errors = errors;
              });

              if (hasError) {
                setProcessedData(currentData);
              } else {
                setIsOpenConfirm(true);
              }
            }}
          >
            Save
          </Button>
          <Button onClick={handleClose} color="default" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOpenConfirm}
        disableBackdropClick={true}
      >
        <DialogTitle style={{ backgroundColor: "#E28020", color: "white" }}>
          Please double-check and confirm your mapping before uploading
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={fileSelected}
              onChange={handleFileChange}
              className={classes.tabs}
            >
              {files ? (
                files.map((x, idx) => {
                  const hasError =
                    processedData[idx]?.errors &&
                    !isEmpty(processedData[idx]?.errors)
                      ? true
                      : false;
                  return (
                    <Tab
                      key={idx}
                      label={x.name}
                      {...a11yProps(idx)}
                      style={{
                        textTransform: "none",
                        color: hasError ? "red" : "inherit",
                      }}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </Tabs>
            {processedData.map((item, idx) => {
              return (
                <TabPanel
                  key={"confirm" + idx}
                  value={fileSelected}
                  index={idx}
                  className={classes.radioGroup}
                >
                  <div className="fields-mapping-container">
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink={true} className="header-selector">
                        File Type
                      </InputLabel>
                      <div style={{ marginTop: "16px" }}>
                        {item.fileType == "0"
                          ? "SPM-PASER"
                          : item.fileType == "1"
                          ? "SPM-PCI"
                          : "SPM-PCI + DP"}
                      </div>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink={true} className="header-selector">
                        Survey Year
                      </InputLabel>
                      <div style={{ marginTop: "16px" }}>{item.SurveyYear}</div>
                    </FormControl>
                    <div style={{ width: "100%" }} />
                    {Object.keys(item.mappingFields).map((key, idx) => {
                      const error = item.errors ? item.errors[key] : "";
                      return key !== "DP" ? (
                        <FormControl
                          key={"confirm-field" + idx}
                          className={classes.formControl}
                          error={error ? true : false}
                        >
                          <InputLabel shrink={true} className="header-selector">
                            {key}
                          </InputLabel>
                          <div style={{ marginTop: "16px" }}>
                            {item.mappingFields[key]}
                          </div>
                        </FormControl>
                      ) : (
                        <React.Fragment key={idx}></React.Fragment>
                      );
                    })}
                    {item.fileType === "2" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <DPInput
                          classes={classes}
                          list={
                            item.mappingFields.DP
                              ? Object.keys(item.mappingFields.DP).map((k) => ({
                                  id: k,
                                  value: item.mappingFields.DP[k],
                                }))
                              : []
                          }
                          readOnly={true}
                          mappingFields={item.mappingFields}
                          fieldList={item.fieldList}
                          handleFieldChange={handleDPFieldChange}
                          handleAdd={handleAddDP}
                          handleRemove={handleRemoeDP}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </TabPanel>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitData}
          >
            Ok
          </Button>
          <Button
            onClick={() => setIsOpenConfirm(false)}
            color="default"
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DPInput = ({
  classes,
  list,
  mappingFields,
  fieldList,
  handleFieldChange,
  handleAdd,
  handleRemove,
}) => {
  return list && mappingFields.DP ? (
    <>
      {list.map((x, idx) => {
        return (
          <div
            key={idx}
            className={classes.formControl}
            style={{ display: "flex" }}
          >
            <FormControl key={idx} style={{ width: "100%" }}>
              <InputLabel shrink={true} className="header-selector">
                DP-{x.id}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleFieldChange(x.id, e.target.value);
                }}
                value={x.value}
              >
                {fieldList.map((x, idx) => (
                  <MenuItem key={idx} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => handleRemove(x.id)}>
              <CloseIcon />
            </Button>
          </div>
        );
      })}
      <Button variant="text" startIcon={<AddIcon />} onClick={handleAdd}>
        DP-Y
      </Button>
    </>
  ) : (
    <></>
  );
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    UploadSurveyedData: (data, callback) =>
      dispatch(UploadSurveyedData(data, callback)),
  })
)(FieldsMappingDialog);
