import React from "react";
import MaterialTable from "material-table";
import { withTranslation } from "react-i18next";
import { TablePagination } from "@material-ui/core";
import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  GetS3Files,
  GetSetting,
  GetUserFileSuccess,
} from "../../services/actions/SettingAction";
import {
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import ManageS3Files from "./ManageS3Files";
import i18n from "../../localization/i18n";

function UserS3List({
  loading,
  data,
  open,
  setOpen,
  getFiles,
  resetFiles,
  selectedUserId,
  setSelectedUserId,
}) {
  const openFileManager = (userId) => {
    setSelectedUserId(userId);
    getFiles(userId);
    setOpen(true);
  };

  return (
    <div>
      <MaterialTable
        title={i18n.t("manage_setting_condition")}
        columns={[
          { title: i18n.t("username"), field: "username" },
          { title: i18n.t("fullname"), field: "fullname" },
          {
            title: i18n.t("bucket_name"),
            field: "bucketName",
          },
        ]}
        data={JSON.parse(JSON.stringify(data)) || []}
        components={{
          Pagination: (props) => (
            <TablePagination {...props} rowsPerPageOptions={[]} />
          ),
        }}
        actions={[
          HasPermissions(MenuType.Settings, PermissionType.Edit) && {
            icon: "perm_media",
            tooltip: "Manage bucket's files",
            iconProps: { style: { color: MAIN_COLOR } },
            onClick: (_e, rowData) => openFileManager(rowData.userId),
          },
        ].filter((x) => x)}
        options={{
          pageSize: 10,
          toolbar: false,
          showTitle: false,
          search: false,
          maxBodyHeight: "300px",
        }}
        isLoading={loading}
      />
      {selectedUserId && (
        <ManageS3Files
          open={open}
          userId={selectedUserId}
          handleClose={() => {
            setOpen(false);
            resetFiles();
          }}
        />
      )}
    </div>
  );
}
export default compose(
  withTranslation("translations"),
  withState("open", "setOpen", false),
  withState("selectedUserId", "setSelectedUserId", null),
  connect(
    (state) => ({
      loading: state.setting.loading,
      data: state.setting.settings,
      surveyedAllocated: state.road.surveyedAllocated,
    }),
    (dispatch) => ({
      getData: () => dispatch(GetSetting()),
      getFiles: (userId) => dispatch(GetS3Files(userId)),
      resetFiles: () => dispatch(GetUserFileSuccess([])),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.getData();
    },
    componentDidUpdate(prevProps) {
      try {
        if (
          this.props.selectedUserId &&
          JSON.stringify(this.props.surveyedAllocated) !==
            JSON.stringify(prevProps.surveyedAllocated)
        ) {
          this.props.getFiles(this.props.selectedUserId);
        }
      } catch (_err) {
        //ignored
      }
    },
  })
)(UserS3List);
