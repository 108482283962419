import Immutable from 'seamless-immutable';
import { UserTypes } from '../actions/UserAction';

const initialState = Immutable({
  loading: false,
  saving: false,
  errors: null,
  users: [],
  userDetail: null,
});

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.FETCHING:
      return state.merge({
        loading: true
      })
    case UserTypes.SAVING:
      return state.merge({
        saving: true
      })
    case UserTypes.SAVED:
      return state.merge({
        saving: false
      })
    case UserTypes.DELETED:
      return state.merge({
        saving: false,
        users: state.users.filter(x => x.id !== action.id)
      })
    case UserTypes.GET_SUCCESS:
      return state.merge({
        loading: false,
        users: action.data,
      })
    case UserTypes.GET_DETAIL_SUCCESS:
      return state.merge({
        loading: false,
        userDetail: action.data,
      })
    case UserTypes.CLEAR_DATA:
      return state.merge({
        userDetail: null,
      })
    case UserTypes.ERROR:
      return state.merge({
        loading: false,
      })
    default:
      return state
  }
}

export default UserReducer