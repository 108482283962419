import Immutable from 'seamless-immutable';
import { GisType } from '../actions/GisAction';

const initialState = Immutable({
  loading: false,
  surveyedDetailData: null,
  showSideContent: true,
  surveyedMapType: 'line',
});

const GisReducer = (state = initialState, action) => {
  switch (action.type) {
    case GisType.LOADING:
      return state.merge({
        loading: true
      })
    case GisType.ERROR:
      return state.merge({
        loading: false,
      })
    case GisType.GET_SURVEYEDDETAIL_SUCCESS:
      return state.merge({
        surveyedDetailData: action.data,
        loading: false,
      })
    case GisType.TOGGLE_SHOW_SIDE_CONTENT:
      return state.merge({
        showSideContent: !state.showSideContent,
        loading: false,
      })
    case GisType.SET_SURVEYED_MAP_TYPE:
      return state.merge({
        surveyedMapType: action.value,
      })
    default:
      return state
  }
}

export default GisReducer