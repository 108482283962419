import React from "react";
import { TileLayer } from "react-leaflet";

export const MAIN_COLOR = "#E28020";
export const DANGER_COLOR = "#dc3545";

// export const ColorByDistress = (distress) => {
//   switch (distress) {
//     case 1:
//       return "#019352";
//     case 2:
//       return "#DBE56A";
//     case 3:
//       return "#FFC000";
//     case 4:
//       return "#FF7D31";
//     case 5:
//       return "#FF0000";
//     default:
//       return MAIN_COLOR;
//   }
// };

// export const DeteriorationRankList = [
//   { title: "1 &nbsp; &nbsp; Excellent", value: [1], color: "#019352" },
//   { title: "2 &nbsp; &nbsp; Very Good", value: [2], color: "#DBE56A" },
//   { title: "3 &nbsp; &nbsp; Good", value: [3], color: "#FFC000" },
//   { title: "4 &nbsp; &nbsp; Fair", value: [4], color: "#FF7D31" },
//   { title: "5 &nbsp; &nbsp; Poor", value: [5], color: "#FF0000" },
// ];

export const BaseMapTile = () => (
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
    id="mapbox.light"
  />
);

export const MenuType = {
  // Dashboard: { label: "Dashboard", value: 1 },
  GIS: { label: "GIS", value: 2 },
  SurveyedList: { label: "Road Surveyed List", value: 3 },
  RoadInfoList: { label: "Road Information List", value: 4 },
  ManageUsers: { label: "Manage Users", value: 5 },
  SurfaceCondition: { label: "Manage Surface", value: 6 },
  Settings: { label: "Settings", value: 7 },
};

export const PermissionType = {
  View: {
    value: 1,
    name: "View",
  },
  Add: {
    value: 2,
    name: "Add",
  },
  Edit: {
    value: 3,
    name: "Edit",
  },
  Delete: {
    value: 4,
    name: "Delete",
  },
  Upload: {
    value: 5,
    name: "Upload",
  },
  Download: {
    value: 6,
    name: "Download",
  },
  AccessPeriod: {
    value: 7,
    name: "AccessPeriod",
  },
  SwitchImages: {
    value: 8,
    name: "SwitchImages",
  },
};

export const UserRole = {
  Admin: {
    value: 1,
    name: "Admin",
  },
  User: {
    value: 2,
    name: "User",
  },
};
