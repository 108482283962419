import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import { UploadSurveyedImages } from "../../../services/actions/RoadAction";

const ImageUploadDialog = ({
  handleClose,
  saveSuccess,
  open,
  files,
  UploadSurveyedImages,
  userId,
  userName,
}) => {
  const [type, setType] = React.useState("0");
  const [surveyYear, setSurveyYear] = React.useState(moment().year());

  const handleSubmitData = () => {
    if (surveyYear) {
      UploadSurveyedImages(
        {
          type,
          surveyYear,
          files,
          userId: userId,
          userName: userName,
        },
        () => {
          saveSuccess();
        }
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} disableBackdropClick={true}>
      <DialogTitle style={{ backgroundColor: "#E28020", color: "white" }}>
        Images upload
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue={"0"}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
          value={type}
          onChange={(e, newValue) => {
            setType(newValue);
          }}
        >
          <FormControlLabel
            value={"0"}
            control={<Radio color="primary" />}
            label="Pic_Original"
            labelPlacement="end"
          />
          <FormControlLabel
            value={"1"}
            control={<Radio color="primary" />}
            label="Pic_Detected"
            labelPlacement="end"
          />
          <TextField
            label="Survey Year"
            style={{ marginBottom: "20px" }}
            value={surveyYear}
            onChange={(e) => setSurveyYear(e.target.value)}
            error={!surveyYear ? true : false}
            helperText={!surveyYear ? "Survey Year is required" : false}
          ></TextField>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleSubmitData}>
          Save
        </Button>
        <Button onClick={handleClose} color="default" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DPInput = ({
  classes,
  list,
  mappingFields,
  fieldList,
  handleFieldChange,
  handleAdd,
  handleRemove,
}) => {
  return list && mappingFields.DP ? (
    <>
      {list.map((x, idx) => {
        return (
          <div
            key={idx}
            className={classes.formControl}
            style={{ display: "flex" }}
          >
            <FormControl key={idx} style={{ width: "100%" }}>
              <InputLabel shrink={true} className="header-selector">
                DP-{x.id}
              </InputLabel>
              <Select
                onChange={(e) => {
                  handleFieldChange(x.id, e.target.value);
                }}
                value={x.value}
              >
                {fieldList.map((x, idx) => (
                  <MenuItem key={idx} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => handleRemove(x.id)}>
              <CloseIcon />
            </Button>
          </div>
        );
      })}
      <Button variant="text" startIcon={<AddIcon />} onClick={handleAdd}>
        DP-Y
      </Button>
    </>
  ) : (
    <></>
  );
};

export default connect(
  (state) => ({
    uploading: state.road.uploading,
  }),
  (dispatch) => ({
    UploadSurveyedImages: (data, callback) =>
      dispatch(UploadSurveyedImages(data, callback)),
  })
)(ImageUploadDialog);
