import React from "react";
import MaterialTable from "material-table";
import { Trans, withTranslation } from "react-i18next";
import { TablePagination, Button } from "@material-ui/core";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { GetUsers, DeleteUser } from "../../services/actions/UserAction";
import { useHistory } from "react-router-dom";
import { Add as AddIcon } from "@material-ui/icons";
import {
  MAIN_COLOR,
  DANGER_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import Swal from "sweetalert2";
import i18n from "../../localization/i18n";

function UserList({ users, deleteUser }) {
  let history = useHistory();
  const actionClicked = (link) => {
    history.push(link);
  };
  return (
    <div className="road-information-container">
      <div>
        {HasPermissions(MenuType.ManageUsers, PermissionType.Add) && (
          <Button
            variant="contained"
            className="upload-btn"
            onClick={() => actionClicked("users/new")}
          >
            <AddIcon /> {i18n.t("add")}
          </Button>
        )}
        <MaterialTable
          title={i18n.t("manage_users")}
          columns={[
            { title: i18n.t("first_name"), field: "firstName" },
            { title: i18n.t("last_name"), field: "lastName" },
            { title: i18n.t("username"), field: "userName" },
            { title: i18n.t("email"), field: "email" },
            // { title: i18n.t(table_title_created_date), field: 'createdDate', type: 'datetime', render: rowData => <>{moment(rowData.createdDate, 'YYYY-MM-DD').local().format('MM/DD/YYYY')}</> },
          ]}
          data={JSON.parse(JSON.stringify(users))}
          components={{
            Pagination: (props) => <TablePagination {...props} />,
          }}
          actions={[
            HasPermissions(MenuType.ManageUsers, PermissionType.Edit) && {
              icon: "edit",
              tooltip: "Edit User",
              iconProps: { style: { color: MAIN_COLOR } },
              onClick: (_e, rowData) => actionClicked(`users/${rowData.id}`),
            },
            HasPermissions(MenuType.ManageUsers, PermissionType.Delete) && {
              icon: "delete",
              tooltip: "Delete User",
              iconProps: { style: { color: DANGER_COLOR } },
              onClick: (_e, rowData) => deleteUser(rowData.id),
            },
          ].filter((x) => x)}
          options={{
            pageSize: 20,
            maxBodyHeight: "calc(100vh - 244px)",
            sorting: false,
            draggable: false,
          }}
        />
      </div>
    </div>
  );
}
export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      loading: state.user.loading,
      users: state.user.users,
    }),
    (dispatch) => ({
      getData: () => dispatch(GetUsers()),
      deleteUser: (id) => {
        Swal.fire({
          title: "Are you sure you want to delete this item?",
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.value) {
            dispatch(DeleteUser(id));
          }
        });
      },
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.getData();
    },
  })
)(UserList);
