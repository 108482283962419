import * as React from "react";
import { Route, Switch } from "react-router";
import Layout from "./layout";
import Login from "../features/Login";
import DashBoard from "../features/DashBoard";
import secureAuth from "./utils/secureAuth";
import RoadInformation from "../features/RoadInformation";
import Gis from "../features/Gis";
import RoadSurveyed from "../features/RoadSurveyed";
import UserForm from "../features/ManageUsers/UserForm";
import UserList from "../features/ManageUsers/UserList";
import PermissionRequired from "../features/PermissionRequired";
import { MenuType, PermissionType } from "./utils/constant";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import SurfaceList from "../features/ManageSurfaceCondition/SurfaceList";
import SurfaceForm from "../features/ManageSurfaceCondition/SurfaceForm";
import Settings from "../features/Settings";

let MainRoutes = ({ isAdmin }) => {
  const reload = () => window.location.reload();
  return (
    <Layout>
      <Switch>
        <Route
          path="/.well-known/pki-validation/fileauth.txt"
          onEnter={reload}
        />
        <Route path="/login" component={Login} />
        <Route path="/permission" component={PermissionRequired} />
        <Route
          exact
          path="/"
          component={secureAuth(Gis, MenuType.Dashboard, PermissionType.View)}
        />
        <Route
          path="/dashboard"
          component={secureAuth(
            DashBoard,
            MenuType.Dashboard,
            PermissionType.View
          )}
        />
        <Route
          path="/gis"
          component={secureAuth(Gis, MenuType.GIS, PermissionType.View)}
        />
        <Route
          path="/surveyed"
          component={secureAuth(
            RoadSurveyed,
            MenuType.SurveyedList,
            PermissionType.View
          )}
        />
        <Route
          path="/surfaces"
          render={({ match: { url } }) => (
            <Switch>
              <Route
                path={`${url}/`}
                component={secureAuth(
                  SurfaceList,
                  MenuType.SurfaceCondition,
                  PermissionType.View
                )}
                exact
              />
              <Route
                path={`${url}/new`}
                component={secureAuth(
                  SurfaceForm,
                  MenuType.SurfaceCondition,
                  PermissionType.Edit
                )}
              />
              <Route
                path={`${url}/:id`}
                component={secureAuth(
                  SurfaceForm,
                  MenuType.SurfaceCondition,
                  PermissionType.Edit
                )}
              />
            </Switch>
          )}
        />
        {isAdmin && (
          <Route
            path="/road"
            component={secureAuth(
              RoadInformation,
              MenuType.RoadInfoList,
              PermissionType.View
            )}
          />
        )}
        {isAdmin && (
          <Route
            path="/users"
            render={({ match: { url } }) => (
              <Switch>
                <Route
                  path={`${url}/`}
                  component={secureAuth(
                    UserList,
                    MenuType.ManageUsers,
                    PermissionType.View
                  )}
                  exact
                />
                <Route
                  path={`${url}/new`}
                  component={secureAuth(
                    UserForm,
                    MenuType.ManageUsers,
                    PermissionType.Edit
                  )}
                />
                <Route
                  path={`${url}/:id`}
                  component={secureAuth(
                    UserForm,
                    MenuType.ManageUsers,
                    PermissionType.Edit
                  )}
                />
              </Switch>
            )}
          />
        )}
        {isAdmin && (
          <Route
            path="/settings"
            component={secureAuth(
              Settings,
              MenuType.Settings,
              PermissionType.View
            )}
          />
        )}
      </Switch>
    </Layout>
  );
};

MainRoutes = compose(
  connect(
    (state) => ({
      authenticated: state.auth.authenticated,
      isAdmin: state.auth.isAdmin,
    }),
    (dispatch) => ({})
  ),
  lifecycle({})
)(MainRoutes);

const routes = <MainRoutes />;

export default routes;
