export const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      boxWidth: 10,
      fontSize: 12
    }
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => `${data['labels'][tooltipItem['index']]}: ${data['datasets'][0]['data'][tooltipItem['index']]}%`,
    },
  },
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value ? `${value} %` : '';
      },
      color: "white",
      font: {
        size: 14,
        weigth: "bold"
      },
      align: "end"
    }
  }
};
