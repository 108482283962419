import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import AuthReducer from "./reducers/AuthReducer";
import RoadReducer from "./reducers/RoadReducer";
import GisReducer from "./reducers/GisReducer";
import SystemReducer from "./reducers/SystemReducer";
import UserReducer from "./reducers/UserReducer";
import SurfaceReducer from "./reducers/SurfaceReducer";
import SettingReducer from "./reducers/SettingReducer";

const rootReducer = combineReducers({
  system: SystemReducer,
  auth: AuthReducer,
  road: RoadReducer,
  gis: GisReducer,
  user: UserReducer,
  surface: SurfaceReducer,
  setting: SettingReducer,
  form: reduxFormReducer,
});

export default rootReducer;
