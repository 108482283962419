import React, { useEffect, useRef } from "react";
import { Map as LeafletMap, Popup, Polyline, Circle } from "react-leaflet";
import L from "leaflet";
import { get, isArray } from "lodash";
import { connect } from "react-redux";

import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import SurveyedDetail from "./LeftContent/SurveyedDetail";
import PinIcon from "../../shared/components/PinIcon";
import { DriftMarker as Marker } from "leaflet-drift-marker";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { geolocated } from "react-geolocated";
import {
  getDistanceFromLatLonInKm,
  coordinateToDistance,
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import Control from "react-leaflet-control";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import shpwrite from "shp-write";
import moment from "moment";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import DPYearSlider from "./DPYearSlider";
import { loading, resetLoading } from "../../services/actions/RoadAction";

const Map = ({
  surveyedAllocated,
  showSurveyedDetail,
  dataDetailSelected,
  closeSurveyedDetail,
  coords,
  showAttributeList,
  rankFilters,
  shouldShowDetail,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  forceReloadMap,
  setForceReloadMap,
  surfaces,
  visiblePointView,
  setVisiblePointView,
  currentZoom,
  setCurrentZoom,
  selectedAddress,
  users,
  userFilter,
  mapEl,
}) => {
  const userPosition = coords
    ? [get(coords, "latitude"), get(coords, "longitude")]
    : [30.55435, -91.03677];
  const currentUser =
    users?.find((x) => x.id == userFilter) ||
    JSON.parse(sessionStorage.getItem("userLogged"));
  const isOldUser = currentUser?.isOldUser ? true : false;
  const [currentCenterPoint, setCurrentCenterPoint] = React.useState(null);
  var selectedPoint = null;
  if (
    dataDetailSelected &&
    dataDetailSelected.startPosition &&
    surveyedAllocated
  ) {
    const startPos = dataDetailSelected.startPosition;
    surveyedAllocated.map((x) => {
      x.polylines.map((p, idx) => {
        if (p[0] == startPos[0] && p[1] == startPos[1]) {
          selectedPoint = x.polylines[idx];
        }
      });
    });
  }
  var center =
    !isOldUser && currentCenterPoint
      ? currentCenterPoint
      : selectedPoint
      ? selectedPoint
      : surveyedAllocated && surveyedAllocated.length > 0
      ? surveyedAllocated[0].polylines[0]
      : userPosition
      ? userPosition
      : [0, 0];
  if (!shouldShowDetail && rankFilters && rankFilters.length > 0) {
    const focusToThis = surveyedAllocated?.find((x) =>
      rankFilters.find((r) => {
        if (r.hasPredict && r.rangeValue) {
          var values = JSON.parse(r.rangeValue);
          return (
            values[0] <= Math.round(x.distressAverage) &&
            Math.round(x.distressAverage) <= values[1]
          );
        } else {
          return r.value == x.distressAverage;
        }
      })
    );
    if (focusToThis) center = focusToThis.polylines[0];
  }

  const surveyedData =
    surveyedAllocated &&
    isArray(surveyedAllocated) &&
    (surveyedMapType === "point"
      ? surveyedAllocated.filter((x, _) => {
          const isMatchDistress = x.polylines.some((p, idx) => {
            const pointDistress = x.polylineDistress.find(
              (d) => d.polylines === JSON.stringify(p)
            );

            return (
              rankFilters &&
              rankFilters.length > 0 &&
              rankFilters.find((r) => {
                const distress = Math.round(
                  pointDistress
                    ? pointDistress.distressAverage
                    : x.distressAverage
                );
                if (r.hasPredict && r.rangeValue) {
                  var values = JSON.parse(r.rangeValue);
                  return values[0] <= distress && distress <= values[1];
                } else {
                  return r.value == distress;
                }
              })
            );
          });

          return (
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 || (rankFilters && isMatchDistress))
          );
        })
      : surveyedAllocated.filter(
          (x, _) =>
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 ||
              (rankFilters &&
                rankFilters.find((r) => {
                  if (r.hasPredict && r.rangeValue) {
                    var values = JSON.parse(r.rangeValue);
                    return (
                      values[0] <= Math.round(x.distressAverage) &&
                      Math.round(x.distressAverage) <= values[1]
                    );
                  } else {
                    return r.value == x.distressAverage;
                  }
                })))
        ));

  var predictSurfaces = surfaces[0]?.hasPredict
    ? surfaces.map((x) => {
        var newItem = { ...x };
        if (newItem.rangeValue) {
          newItem.rangeValue = JSON.parse(x.rangeValue);
        }
        return newItem;
      })
    : null;

  const colorByDistress = (value) => {
    const distress = Math.round(value);
    if (predictSurfaces) {
      const surface = predictSurfaces.find(
        (x) =>
          x.rangeValue &&
          x.rangeValue[0] <= distress &&
          distress <= x.rangeValue[1]
      );
      if (surface) {
        return surface.color;
      }
    } else {
      var condition = (surfaces || []).find((x) => x.value == distress);
      if (condition) {
        return condition.color;
      }
    }

    return MAIN_COLOR;
  };

  useEffect(() => {
    if (selectedAddress?.x) {
      const map = mapEl?.current?.leafletElement;
      if (map) {
        map.setView([selectedAddress.y, selectedAddress.x], currentZoom);
      }
      setForceReloadMap(true);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!dataDetailSelected && surveyedAllocated) {
      const centerPoint = mapEl.current.leafletElement.getCenter();
      setCurrentCenterPoint([centerPoint.lat, centerPoint.lng]);
    } else {
      setCurrentCenterPoint(null);
    }
  }, [dataDetailSelected, surveyedAllocated]);

  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={currentZoom}
      maxZoom={18}
      style={{
        height: `calc(100vh - ${
          showAttributeList
            ? window.innerHeight < 700
              ? "264px"
              : "364px"
            : "64px"
        })`,
      }}
      onPopupClose={() => closeSurveyedDetail()}
      boundsOptions={
        shouldShowDetail && {
          paddingBottomRight: [250, 0],
        }
      }
      onZoomEnd={(e) => {
        if (e.target._zoom < 17) {
          setMapType("line");
        }
        setVisiblePointView(e.target._zoom >= 17);
        setForceReloadMap(true);
        setCurrentZoom(e.target._zoom);
      }}
      onDragEnd={() => {
        const centerPoint = mapEl?.current?.leafletElement?.getCenter();
        if (selectedPoint && shouldShowDetail) {
          const distanceFromCenter = getDistanceFromLatLonInKm(
            centerPoint.lat,
            centerPoint.lng,
            selectedPoint[0],
            selectedPoint[1]
          );
          if (distanceFromCenter > 1) {
            closeSurveyedDetail();
            // alert("The detail marker is closed because it's out of the map.")
          }
        }
        if (centerPoint) {
          setCurrentCenterPoint([centerPoint.lat, centerPoint.lng]);
        }
        setForceReloadMap(true);
      }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      <DPYearSlider closeSurveyedDetail={closeSurveyedDetail} />
      <Control position="topright">
        <ButtonGroup size="small" className="map-panel-type">
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
            disabled={!visiblePointView}
          >
            Point
          </Button>
        </ButtonGroup>
      </Control>
      {surveyedData && surveyedData.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={dowloadShapeFile}
          >
            <Tooltip title={<Trans>download_shapefile</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button>
        </Control>
      )}
      {shouldShowDetail && dataDetailSelected && (
        <Marker
          className="popup-road-detail"
          ref={(el) => {
            if (el && el.leafletElement)
              window.setTimeout(() => el.leafletElement.openPopup());
          }}
          icon={PinIcon}
          position={selectedPoint ? selectedPoint : center}
          duration={1000}
        >
          <Popup
            className={`popup-road-detail ${
              surveyedMapType === "line" ? "" : "popup-content-point"
            }`}
          >
            <SurveyedDetail
              {...dataDetailSelected}
              showSurveyedDetail={(
                startPos,
                startDate,
                polylines,
                lastPositionData
              ) => {
                showSurveyedDetail(
                  startPos,
                  startDate,
                  polylines,
                  surveyedData,
                  lastPositionData
                );
              }}
              surveyedData={surveyedData}
            />
          </Popup>
        </Marker>
      )}
      {!forceReloadMap && (
        <>
          {surveyedData &&
            surveyedData.map((x, idx) => {
              if (!mapEl?.current) return <></>;
              const startPos = x.polylines[0];
              const endPos = x.polylines[x.polylines.length - 1];
              const map = mapEl.current.leafletElement;
              if (
                map
                  .getBounds()
                  .contains(new L.latLng(startPos[0], startPos[1])) ||
                map.getBounds().contains(new L.latLng(endPos[0], endPos[1]))
              ) {
                const distance = getDistanceFromLatLonInKm(
                  startPos[0],
                  startPos[1],
                  endPos[0],
                  endPos[1]
                );
                const totalDistance = coordinateToDistance(x.polylines);
                return surveyedMapType === "line" ? (
                  <Polyline
                    key={idx}
                    color={colorByDistress(x.distressAverage)}
                    positions={
                      totalDistance - distance < 0.0003
                        ? [startPos, endPos]
                        : x.polylines
                    }
                    onclick={() => {
                      showSurveyedDetail(
                        x.polylines[0],
                        x.surveyedDate,
                        x.polylines,
                        surveyedData
                      );
                    }}
                  />
                ) : (
                  x.polylines.map((p, idx) => {
                    const pointDistress = x.polylineDistress.find(
                      (d) => d.polylines === JSON.stringify(p)
                    );
                    if (
                      rankFilters &&
                      rankFilters.length > 0 &&
                      !rankFilters.find((r) => {
                        var distress = Math.round(
                          pointDistress
                            ? pointDistress.distressAverage
                            : x.distressAverage
                        );
                        if (r.hasPredict && r.rangeValue) {
                          var values = JSON.parse(r.rangeValue);
                          return values[0] <= distress && distress <= values[1];
                        } else {
                          return r.value == distress;
                        }
                      })
                    ) {
                      return <div key={idx}></div>;
                    }

                    const color = colorByDistress(
                      pointDistress
                        ? pointDistress.distressAverage
                        : x.distressAverage
                    );
                    return (
                      <Circle
                        key={idx}
                        className="point-surveyed-type"
                        onclick={() => {
                          showSurveyedDetail(
                            p,
                            x.surveyedDate,
                            x.polylines,
                            surveyedData
                          );
                        }}
                        center={p}
                        radius={1.5}
                        color={color}
                        fillColor={color}
                      ></Circle>
                    );
                  })
                );
              }
            })}
        </>
      )}
    </LeafletMap>
  );
};

export default compose(
  withTranslation("translations"),
  withState("forceReloadMap", "setForceReloadMap", false),
  withState("visiblePointView", "setVisiblePointView", false),
  withState("currentZoom", "setCurrentZoom", 16),
  geolocated({
    positionOptions: { enableHighAccuracy: false },
    userDecisionTimeout: 5000,
  }),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      rankFilters: state.road.rankFilters,
      selectedAddress: state.road.selectedAddress,
      surveyedMapType: state.gis.surveyedMapType,
      loading: state.gis.loading,
      surfaces: state.surface.surfaces,
      userFilter: state.road.userFilter,
      loggedUser: state.auth.user,
      users: state.user.users,
      yearFilter: state.road.yearFilter,
      showSideContent: state.gis.showSideContent,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      showLoading: () => dispatch(loading()),
      hideLoading: () => dispatch(resetLoading()),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({
        surveyedMapType,
        surveyedAllocated,
        rankFilters,
        showPermissionRequired,
        surfaces,
        userFilter,
        loggedUser,
        showLoading,
        hideLoading,
      }) =>
      async () => {
        if (!HasPermissions(MenuType.GIS, PermissionType.Download)) {
          return showPermissionRequired();
        }
        showLoading();
        try {
          const surveyedData =
            surveyedAllocated &&
            isArray(surveyedAllocated) &&
            surveyedAllocated.filter(
              (x, idx) =>
                x.polylines &&
                x.polylines.length > 1 &&
                (rankFilters.length === 0 ||
                  (rankFilters &&
                    rankFilters.find((r) => {
                      if (r.hasPredict && r.rangeValue) {
                        var values = JSON.parse(r.rangeValue);
                        return (
                          values[0] <= Math.round(x.distressAverage) &&
                          Math.round(x.distressAverage) <= values[1]
                        );
                      } else {
                        return r.value == x.distressAverage;
                      }
                    })))
            );
          const fileName = `pms_shapefile_${
            surveyedMapType === "line" ? "line_" : "point_"
          }${moment().format("DDMMYYYYhhmmss")}`;
          var options = {
            file: fileName,
            folder: fileName,
            types: {
              point: "points",
              polygon: "polygons",
              polyline: "polylines",
            },
          };
          shpwrite.download(
            {
              type: "FeatureCollection",
              features: [
                ...(await getGeoJsonFromSurveyedData(
                  userFilter || loggedUser.id,
                  surfaces,
                  surveyedData,
                  surveyedMapType
                )),
              ],
            },
            options
          );
        } catch (error) {
          console.error(error);
        }
        hideLoading();
      },
  }),
  lifecycle({
    componentWillUpdate(nextProps) {
      try {
        if (
          JSON.stringify(this.props.surveyedAllocated) !==
          JSON.stringify(nextProps.surveyedAllocated)
        ) {
          this.props.setForceReloadMap(true);
        }
      } catch (_err) {
        //ignored
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.forceReloadMap) this.props.setForceReloadMap(false);
      try {
        if (
          (!this.props.showSideContent && prevProps.showSideContent) ||
          JSON.stringify(prevProps.rankFilters) !==
            JSON.stringify(this.props.rankFilters)
        ) {
          setTimeout(() => {
            this.props.setForceReloadMap(true);
          }, 500);
        }
      } catch (_err) {
        //ignored
      }
    },
  })
)(Map);
