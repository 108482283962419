import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import {
  GetRoadSurveyed,
  GetYearsSlider,
  SetYearSelected,
} from "../../services/actions/RoadAction";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import $ from "jquery";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const DPYearSlider = ({
  userFilter,
  position,
  dpYears,
  GetYearsSliderData,
  yearFilter,
  SetYearFilter,
  GetRoadSurveyed,
  showSideContent,
}) => {
  const handleChange = (event, newValue) => {
    SetYearFilter(dpYears[newValue].year);
    GetRoadSurveyed(userFilter);
  };

  const correctActiveLabels = () => {
    var labels = $(".MuiSlider-markActive");
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      $(label).removeClass("real-active");
      if (i == labels.length - 1) {
        $(label).addClass("real-active");
      }
    }

    var points = $(".MuiSlider-markLabelActive");
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      $(point).removeClass("real-active");
      if (i == points.length - 1) {
        $(point).addClass("real-active");
      }
    }
  };

  useEffect(() => {
    if (userFilter) {
      GetYearsSliderData();
    }
  }, [userFilter]);

  useEffect(() => {
    if (dpYears) {
      SetYearFilter(moment().year());
      correctActiveLabels();
    }
  }, [dpYears]);

  useEffect(() => {
    correctActiveLabels();
  }, [yearFilter]);

  const marks = dpYears
    ? dpYears.map((x, idx) => ({
        value: idx,
        label: `${x.year} (${x.isPredict ? "Predicted" : "Actual"})`,
        isPredict: x.isPredict,
      }))
    : [];

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topleft;
  let yearIndex = dpYears.findIndex((x) => x.year == yearFilter) ?? 0;

  return (
    <div className={positionClass}>
      <div className="leaflet-control">
        {marks?.length ? (
          <PrettoSlider
            style={{
              marginLeft: showSideContent ? "470px" : "50px",
              width: `${(50 + 5 * marks.length) * marks.length}px`,
            }}
            defaultValue={marks[0]}
            valueLabelDisplay="off"
            value={yearIndex}
            marks={marks}
            min={Number(marks[0].value)}
            max={Number(marks[marks.length - 1].value)}
            step={1}
            onChange={handleChange}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const PrettoSlider = withStyles({
  root: {
    color: "#fff",
    height: 6,
    "& .MuiSlider-mark": {
      width: "20px",
      height: "20px",
      marginLeft: "-10px",
      top: "5px",
      borderRadius: "50%",
      border: "2px solid #d3d3d3",
    },
    "& .MuiSlider-markActive": {
      border: "2px solid #d3d3d3",
      width: "18px",
      height: "18px",
      opacity: "1",
      backgroundColor: "#fff",
    },
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#E28020",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "black",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 0.8,
    backgroundColor: "black",
  },
})(Slider);

export default connect(
  (state) => ({
    dpYears: state.road.dpYears,
    userFilter: state.road.userFilter,
    yearFilter: state.road.yearFilter,
    showSideContent: state.gis.showSideContent,
  }),
  (dispatch) => ({
    GetYearsSliderData: () => dispatch(GetYearsSlider()),
    SetYearFilter: (year) => dispatch(SetYearSelected(year)),
    GetRoadSurveyed: (userId) => dispatch(GetRoadSurveyed(null, userId)),
  })
)(DPYearSlider);
