import { baseFetch } from "../../shared/utils/fetchUtils";
import { SetError } from "./SystemAction";
import { ApiEndpoint } from "../../env.config";
import { getRoadSurveyedSuccess } from "./RoadAction";
import moment from "moment";

export const AuthTypes = {
  LOGGING_IN: "LOGGING_IN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  ERROR: "LOGIN_FAIL",
  RESET_AUTH: "RESET_AUTH",
  CLEAR_ERROR: "CLEAR_ERROR",
};

export const LoggingIn = () => ({
  type: AuthTypes.LOGGING_IN,
});

export const LoginSuccess = (json) => ({
  type: AuthTypes.LOGIN_SUCCESS,
  data: json,
});

export const LoginFail = () => ({
  type: AuthTypes.LOGIN_FAIL,
});

const SetSystemError = SetError;

export const Logout = () => ({
  type: AuthTypes.LOGOUT,
});

export const ResetAuth = () => ({
  type: AuthTypes.RESET_AUTH,
});

export function login(username, password) {
  return function (dispatch) {
    dispatch(LoggingIn());

    return baseFetch(`${ApiEndpoint}/user/authenticate`, {
      method: "POST",
      body: JSON.stringify({ Username: username, Password: password }),
    })
      .then((json) => {
        // Check access period
        if (
          json.accessPeriod &&
          moment().isAfter(moment(json.accessPeriod, "YYYY-MM-DD"))
        ) {
          dispatch(LoginFail());
          dispatch(
            SetSystemError({
              message: `<p style="color: red;">${
                json.expirationMessage ||
                "GIS access has EXPIRED. <br/> Please contact <a href='https://www.cnexco.com/contact'>NHSA</a> for further details."
              }</p>`,
            })
          );
        } else {
          return dispatch(LoginSuccess(json));
        }
      })
      .catch((errObj) => {
        dispatch(LoginFail());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(getRoadSurveyedSuccess(null));
    dispatch(Logout());
  };
}
