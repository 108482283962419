import React, { useEffect } from "react";
import { Grid, InputLabel, Select, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import {
  ShowAttributeList,
  GetDateThatHaveData,
  ResetFilterDate,
  SelectUserFilter,
  SetUserFilter,
  SetSelectedAddress,
} from "../../../services/actions/RoadAction";
import { withTranslation, Trans } from "react-i18next";
import { GetUsers } from "../../../services/actions/UserAction";
import { Autocomplete } from "@material-ui/lab";
import OpenStreetMapProvider from "leaflet-geosearch/lib/providers/openStreetMapProvider";
import { getDistanceFromLatLonInKm } from "../../../shared/utils/objectExtensions";
import { orderBy } from "lodash";
import FilterCardOld from "./FilterCard_Old";

var delaySearch = null;
const FilterCard = ({
  SetAddress,
  isAdmin,
  userFilter,
  SetUserId,
  users,
  loggedUser,
  surveyedAllocated,
  mapEl,
  closeSurveyedDetail,
}) => {
  const [searching, setSearching] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const currentUser =
    users?.find((x) => x.id == userFilter) ||
    JSON.parse(sessionStorage.getItem("userLogged"));
  const isOldUser = currentUser?.isOldUser ? true : false;

  const handleSearch = (value) => {
    clearTimeout(delaySearch);

    delaySearch = setTimeout(async () => {
      setSearching(true);
      try {
        const provider = new OpenStreetMapProvider({
          params: {
            "accept-language": "en", // render results in English
          },
        });
        const results = await provider.search({ query: value });
        const centerPoint = mapEl?.current?.leafletElement?.getCenter();
        if (results?.length) {
          let selectedPoint = centerPoint
            ? [centerPoint.lat, centerPoint.lng]
            : surveyedAllocated
            ? surveyedAllocated[0].polylines[0]
            : [0, 0];
          let orderResults = results.map((r) => {
            let item = { ...r };
            item.distanceFromCenter = getDistanceFromLatLonInKm(
              selectedPoint[0],
              selectedPoint[1],
              r.y,
              r.x
            );
            return item;
          });
          setSearchOptions(
            orderBy(orderResults, ["distanceFromCenter"], ["asc"])
          );
        } else {
          setSearchOptions(results);
        }

        setSearching(false);
      } catch (error) {
        setSearching(false);
      }
    }, 400);
  };

  const handleSelectSearch = (value) => {
    if (!value) {
      setSearchOptions([]);
    }
    SetAddress(value);
  };

  return isOldUser ? (
    <FilterCardOld />
  ) : (
    <div className="filter-card">
      <Grid container spacing={3}>
        <Grid item md={12} style={{ width: "100%" }}>
          {isAdmin && users && users.length > 0 && (
            <div style={{ marginBottom: "10px" }}>
              <InputLabel
                htmlFor="user"
                style={{ color: "white", paddingBottom: "5px" }}
              >
                <Trans>user</Trans>
              </InputLabel>
              <FormControl className="field" variant="outlined">
                <Select
                  native
                  name="user"
                  value={userFilter}
                  onChange={(e) => {
                    closeSurveyedDetail();
                    SetUserId(e.target.value);
                  }}
                >
                  {loggedUser && (
                    <option key={loggedUser.id} value={loggedUser.id}>
                      {loggedUser.userName}
                    </option>
                  )}
                  {users.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.userName} - {x.firstName} {x.lastNameF}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <Autocomplete
            options={searchOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                variant="filled"
                onChange={(e) => handleSearch(e.target.value)}
              />
            )}
            noOptionsText={searching ? "Loading..." : "No options"}
            onChange={(e, value) => handleSelectSearch(value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      selectedAddress: state.road.selectedAddress,
      uploading: state.road.uploading,
      isAdmin: state.auth.isAdmin,
      loggedUser: state.auth.user,
      datesHaveData: state.road.datesHaveData,
      userFilter: state.road.userFilter,
      users: state.user.users,
      surveyedAllocated: state.road.surveyedAllocated,
    }),
    (dispatch) => ({
      SetUserId: (userId) => dispatch(SelectUserFilter(userId)),
      ResetUserFilter: () => dispatch(SetUserFilter(null)),
      SetAddress: (address) => dispatch(SetSelectedAddress(address)),
      ShowAttributeList: () => dispatch(ShowAttributeList()),
      GetDates: (month, year) => dispatch(GetDateThatHaveData(month, year)),
      ResetFilter: () => dispatch(ResetFilterDate()),
      getUsers: () => dispatch(GetUsers()),
    })
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.isAdmin) {
        this.props.getUsers();
      } else {
        this.props.SetUserId(this.props.loggedUser.id);
      }
    },
    componentWillUnmount() {
      this.props.ResetUserFilter();
    },
  })
)(FilterCard);
