export const lineChartOptions = {
  responsive: true,
  bezierCurve: false,
  plugins: {
    datalabels: {
      display: false
    }
  },
  legend: {
    display: false
  },
  elements: {
    line: {
      tension: 0
    },
    point: {
      radius: 0.5
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "#595959",
          drawTicks: false
        },
        ticks: {
          fontColor: "#bfbfbf",
          padding: 16
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          padding: 16,
          stepSize: 0.5,
          fontColor: "#bfbfbf",
          callback: function(label, index, labels) {
            return label.toFixed(1);
          }
        },
        gridLines: {
          color: "#595959",
          drawTicks: false
        }
      }
    ]
  }
};

export const lineChartData = {
  labels: [
    "2018",
    "2019"
  ],
  datasets: [
    {
      data: [3, 0],
      backgroundColor: "transparent",
      borderColor: "#8ab6c1",
      borderWidth: 4
    }
  ]
};
