import { Typography, Box } from "@material-ui/core";
import React, { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: error.message });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box p={3}>
          <Typography variant="h5" color="error" style={{ fontWeight: "bold" }}>
            Something went wrong.
          </Typography>
          <Typography color="error" variant="h6">
            {this.state.error}
          </Typography>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
