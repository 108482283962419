
import { Schema } from 'yup';

const validator = <T>(schema: Schema<T>) => async (formValues: any) => {
  try {
    await schema.validate(formValues, { abortEarly: false })
    return {}
  } catch (errors) {
    throw errors.inner.reduce(
      (errors: any, err: any) => ({
        ...errors,
        [err.path]: err.message
      }),
      {}
    )
  }
}

export default validator;