import { authFetch } from "../../shared/utils/fetchUtils";
import {
  SetError,
  SetSuccess,
} from "./SystemAction";
import { ApiEndpoint } from "../../env.config";

export const SettingTypes = {
  FETCHING: "setting/FETCHING",
  SAVING: "setting/SAVING",
  SAVED: "setting/SAVED",
  GET_SUCCESS: "setting/GET_SUCCESS",
  GET_SYSTEM_SUCCESS: "setting/GET_SYSTEM_SUCCESS",
  GET_USER_FILE_SUCCESS: "setting/GET_USER_FILE_SUCCESS",
  ERROR: "setting/ERROR",
  CLEAR_ERROR: "setting/CLEAR_ERROR",
};

const Fetching = () => ({
  type: SettingTypes.FETCHING,
});

const Saving = () => ({
  type: SettingTypes.SAVING,
});

const Saved = () => ({
  type: SettingTypes.SAVED,
});

const Error = () => ({
  type: SettingTypes.ERROR,
});

const GetSettingSuccess = (data) => ({
  type: SettingTypes.GET_SUCCESS,
  data,
});

const GetSystemSettingSuccess = (data) => ({
  type: SettingTypes.GET_SYSTEM_SUCCESS,
  data,
});

export const GetUserFileSuccess = (data) => ({
  type: SettingTypes.GET_USER_FILE_SUCCESS,
  data,
});

const SetSystemError = SetError;

export const ClearSettingData = () => ({
  type: SettingTypes.CLEAR_DATA,
});

export function GetSetting() {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/setting`, { method: "GET" })
      .then((json) => dispatch(GetSettingSuccess(json)))
      .catch((errObj) => {
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function GetSystemSetting() {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/setting/system`, { method: "GET" })
      .then((json) => dispatch(GetSystemSettingSuccess(json)))
      .catch((errObj) => {
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function GetS3Files(userId) {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/setting/files/${userId}`, { method: "GET" })
      .then((json) => dispatch(GetUserFileSuccess(json)))
      .catch((errObj) => {
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function UpdateSetting(data) {
  return function (dispatch) {
    dispatch(Saving());

    return authFetch(`${ApiEndpoint}/setting`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((_json) => {
        dispatch(Saved());
        dispatch(SetSuccess("Save successfully!"));
      })
      .catch((errObj) => {
        dispatch(Saved());
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}