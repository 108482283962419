import React, { useRef } from "react";
import { Map as LeafletMap, Polyline, Circle } from "react-leaflet";
import { connect } from "react-redux";

import { compose, withState, withHandlers, lifecycle } from "recompose";
import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import { get } from "lodash";
import { geolocated } from "react-geolocated";
import Control from "react-leaflet-control";
import { Button, ButtonGroup } from "@material-ui/core";
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import moment from "moment";
import shpwrite from "shp-write";
import {
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import { loading, resetLoading } from "../../services/actions/RoadAction";

const Map = ({
  data,
  coords,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  surfaces,
}) => {
  const mapEl = useRef(null);
  const center = get(data, "[0].polylines[0]", [
    get(coords, "latitude", 30.55435),
    get(coords, "longitude", -91.03677),
  ]);

  const mappZoom = 16;

  const colorByDistress = (value) => {
    var condition = (surfaces || []).find((x) => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };

  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={mappZoom}
      maxZoom={18}
      style={{ height: `calc(100% - 64px)` }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      <Control position="topright">
        <ButtonGroup size="small" className="map-panel-type">
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
          >
            Point
          </Button>
        </ButtonGroup>
      </Control>
      {data && data.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={() => dowloadShapeFile(data)}
          >
            <DownloadIcon />
          </Button>
        </Control>
      )}
      {data &&
        data.map((x, idx) => {
          const color = colorByDistress(x.distressAverage);
          return surveyedMapType === "line" ? (
            <Polyline
              key={idx}
              color={colorByDistress(x.distressAverage)}
              positions={x.snappedPoints}
            />
          ) : (
            x.polylines.map((p, idx) => (
              <Circle
                key={idx}
                className="point-surveyed-type"
                center={p}
                radius={1.5}
                color={color}
                fillColor={color}
              ></Circle>
            ))
          );
        })}
    </LeafletMap>
  );
};

export default compose(
  withState("zoom", "setZoom", 16),
  withState("center", "setCenter", [39.94792279, -79.99223758]),
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  }),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      surveyedMapType: state.gis.surveyedMapType,
      surfaces: state.surface.surfaces,
      userFilter: state.road.userFilter,
      loggedUser: state.auth.user,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      showLoading: () => dispatch(loading()),
      hideLoading: () => dispatch(resetLoading()),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({
        surveyedMapType,
        showPermissionRequired,
        surfaces,
        userFilter,
        loggedUser,
        showLoading,
        hideLoading,
      }) =>
      async (surveyedData) => {
        if (!HasPermissions(MenuType.SurveyedList, PermissionType.Download)) {
          return showPermissionRequired();
        }
        showLoading();
        try {
          const fileName = `pms_shapefile_${
            surveyedMapType === "line" ? "line_" : "point_"
          }${moment().format("DDMMYYYYhhmmss")}`;
          var options = {
            file: fileName,
            folder: fileName,
            types: {
              point: "points",
              polygon: "polygons",
              polyline: "polylines",
            },
          };
          shpwrite.download(
            {
              type: "FeatureCollection",
              features: [
                ...(await getGeoJsonFromSurveyedData(
                  userFilter || loggedUser.id,
                  surfaces,
                  surveyedData,
                  surveyedMapType
                )),
              ],
            },
            options
          );
        } catch (error) {
          console.error(error);
        }
        hideLoading();
      },
  })
)(Map);
