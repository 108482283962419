import React, { Component, useEffect } from "react";
import NavMenu from "./navMenu";
import { ErrorBoundary } from "./ErrorBoundary";
import { Route } from "react-router";
import { connect } from "react-redux";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import Fullscreen from 'react-fullscreen-crossbrowser';
import { compose, lifecycle, withState } from "recompose";
import Swal from "sweetalert2";
import {
	ClearError,
	StopProcessing,
	ClearWarning,
	ClearSuccess,
	ClosePermissionRequired,
} from "../services/actions/SystemAction";
import {
	Dialog,
	DialogContent,
	Zoom,
	LinearProgress,
	DialogTitle,
	DialogActions,
	Button,
	Typography,
} from "@material-ui/core";
import { GetSystemSetting } from "../services/actions/SettingAction";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const Layout = (props) => {
	const { processing, showNotice, setShowNotice } = props;

	return (
		<div className="full-screenable-node">
			<div
				id="wrapper"
				className={`animate ${props.authenticated && "authenticated"}`}
			>
				<Route component={NavMenu} />
				<ErrorBoundary>{props.children}</ErrorBoundary>
				{processing && <Processing {...props} />}
				{showNotice && (
					<UpdateNotice
						message={props.system?.updateNote}
						showNotice={showNotice}
						setShowNotice={setShowNotice}
					/>
				)}
			</div>
		</div>
	);
};

const Processing = ({ processing, stopProcessing }) => {
	return (
		<Dialog
			open={processing ? true : false}
			TransitionComponent={Transition}
			keepMounted
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className="pms-processing"
		>
			<DialogTitle>Processing</DialogTitle>
			<DialogContent>
				<div className="process-indicator">
					<label>{processing}%</label>
					<LinearProgress variant="determinate" value={processing} />
				</div>
			</DialogContent>
			{processing === 100 && (
				<DialogActions>
					<Button
						variant="contained"
						autoFocus
						onClick={() => stopProcessing()}
					>
						Ok
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};

const UpdateNotice = ({ message, showNotice, setShowNotice }) => {
	return (
		<Dialog
			open={showNotice}
			TransitionComponent={Transition}
			keepMounted
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className="pms-processing"
		>
			<DialogTitle>
				<b style={{ color: "#f44336" }}>Notice</b>
			</DialogTitle>
			<DialogContent>
				<Typography style={{ fontWeight: "bold" }}>{message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					autoFocus
					onClick={() => setShowNotice(false)}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default compose(
	withState("showNotice", "setShowNotice", false),
	connect(
		(state) => ({
			authenticated: state.auth.authenticated,
			error: state.system.error,
			warning: state.system.warning,
			success: state.system.success,
			requiredPermissionModal: state.system.requiredPermissionModal,
			processing: state.system.processing,
			system: state.setting.system,
		}),
		(dispatch) => ({
			resetError: () => dispatch(ClearError()),
			resetWarning: () => dispatch(ClearWarning()),
			resetSuccess: () => dispatch(ClearSuccess()),
			stopProcessing: () => dispatch(StopProcessing()),
			closePermissionRequired: () => dispatch(ClosePermissionRequired()),
			getUpdateNotice: () => dispatch(GetSystemSetting()),
		})
	),
	lifecycle({
		componentDidMount() {
			if (this.props.authenticated) {
				this.props.getUpdateNotice();
			}
		},
		componentDidUpdate(prevProps) {
			if (!prevProps.authenticated && this.props.authenticated) {
				this.props.getUpdateNotice();
			}
			if (
				!prevProps.system &&
				this.props.system &&
				this.props.system.updateNote
			) {
				this.props.setShowNotice(true);
			}

			const { error, warning, success, requiredPermissionModal } = this.props;
			if (error) {
				Swal.fire({
					html: error,
					type: "error",
					showCancelButton: false,
					confirmButtonText: "Ok",
				}).then(() => {
					this.props.resetError();
				});
			}

			if (warning) {
				Swal.fire({
					text: warning,
					type: "warning",
					showCancelButton: false,
					confirmButtonText: "Ok",
				}).then(() => {
					this.props.resetWarning();
				});
			}

			if (success) {
				Swal.fire({
					text: success,
					type: "success",
					showCancelButton: false,
					confirmButtonText: "Ok",
				}).then(() => {
					this.props.resetSuccess();
				});
			}

			if (requiredPermissionModal) {
				Swal.fire({
					title: "Permission required!!!",
					text: "Please contact to administrator to get access",
					type: "error",
					showCancelButton: false,
					confirmButtonText: "Close",
				}).then(() => {
					this.props.closePermissionRequired();
				});
			}
		},
	})
)(Layout);
