import Immutable from 'seamless-immutable';
import { AuthTypes } from '../actions/AuthAction';
import { get } from 'lodash';

const getInitial = (name) => {
  var userString = sessionStorage.getItem('userLogged');
  switch (name) {
    case "authenticated":
      return userString ? true : false;
    case "user":
      return userString ? JSON.parse(userString) : null;
    case "isAdmin":
      if (!userString) return false;
      const roles = get(JSON.parse(userString), "roles", []);
      return roles.find(x => x.name === "superadmin" || x.name === "admin") ? true : false;
    default:
      return;
  }
}

const initialState = Immutable({
  authenticating: false,
  authenticated: getInitial("authenticated"),
  user: getInitial("user"),
  isAdmin: getInitial("isAdmin")
});

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthTypes.LOGGING_IN:
      return state.merge({
        authenticating: true
      })
    case AuthTypes.LOGIN_SUCCESS:
      sessionStorage.setItem('userLogged', JSON.stringify(action.data));
      const roles = action.data.roles;
      const isAdmin = roles.find(x => x.name === "superadmin" || x.name === "admin");

      return state.merge({
        authenticating: false,
        authenticated: true,
        user: action.data,
        isAdmin: isAdmin ? true : false,
      })
    case AuthTypes.LOGIN_FAIL:
      return state.merge({
        authenticating: false,
        authenticated: false,
      })
    case AuthTypes.RESET_AUTH:
      return state.merge({
        authenticating: false,
        authenticated: false,
        error: ''
      })
    case AuthTypes.LOGOUT:
      sessionStorage.removeItem('userLogged');
      return state.merge({
        authenticating: false,
        authenticated: false,
        error: ''
      })
    default:
      return state
  }
}

export default AuthReducer