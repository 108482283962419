import React from "react";
import MaterialTable from "material-table";
import { compose, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import {
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import moment from "moment-timezone";
import { TablePagination } from "@material-ui/core";
import { CsvBuilder } from "filefy";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import { isArray } from "lodash";

const GetData = (surveyedData, attributeData) => {
  var result = [];
  surveyedData.map((surveyed) => {
    const firstItem = attributeData.find(
      (x) =>
        x.latitude == surveyed.polylines[0][0] &&
        x.longitude == surveyed.polylines[0][1]
    );
    result.push({
      userId: firstItem.userId,
      roadName: firstItem.roadName,
      videoDate: firstItem.videoDate,
      distress: surveyed.distressAverage,
      start_point: surveyed.polylines[0],
      end_point: surveyed.polylines[4],
      polylines: surveyed.polylines.map((p) => {
        const polyData = attributeData.find(
          (x) => x.latitude == p[0] && x.longitude == p[1]
        );
        return {
          ...polyData,
        };
      }),
    });
  });

  return result;
};

const exportCsv = (columnList, initialData) => {
  const columns = columnList.filter((columnDef) => {
    return !columnDef.hidden && columnDef.field && columnDef.export !== false;
  });

  const data = initialData.map((rowData) =>
    columns.map((columnDef) => {
      return columnDef.render
        ? columnDef.render(rowData)
        : rowData[columnDef.field];
    })
  );

  const builder = new CsvBuilder(
    "pms_pasco_attributes_" + moment().format("DDMMYYYYhhmmss") + ".csv"
  );
  builder
    .setDelimeter(",")
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile();
};

const AttributeList = ({
  attributeData,
  selectedRow,
  setSelectedRow,
  showSurveyedDetail,
  surveyedAllocated,
  surveyedMapType,
  rankFilters,
}) => {
  const { innerHeight: screenHeight } = window;
  const surveyedData =
    surveyedAllocated &&
    isArray(surveyedAllocated) &&
    surveyedAllocated.filter(
      (x, _) =>
        x.polylines &&
        x.polylines.length > 1 &&
        (rankFilters.length === 0 ||
          (rankFilters &&
            rankFilters.find((r) => {
              if (r.hasPredict && r.rangeValue) {
                var values = JSON.parse(r.rangeValue);
                return (
                  values[0] <= x.distressAverage &&
                  x.distressAverage <= values[1]
                );
              } else {
                return r.value == x.distressAverage;
              }
            })))
    );

  const data =
    attributeData && surveyedData ? GetData(surveyedData, attributeData) : null;
  const isLineType = surveyedMapType === "line";
  return (
    <div className="attribute-list">
      {data && data.length > 0 && (
        <MaterialTable
          maxBodyHeight={50}
          title="Road surveyed list"
          columns={
            isLineType
              ? [
                  { title: "uId", field: "userId" },
                  { title: "RoadName", field: "roadName" },
                  {
                    title: "Surveyed Date",
                    field: "videoDate",
                    render: (rowData) => (
                      <>
                        {moment(rowData.videoDate, "YYYY-MM-DD")
                          .local()
                          .format("DD/MM/YYYY")}
                      </>
                    ),
                  },
                  { title: "Distress", field: "distress" },
                  { title: "Start", field: "start_point" },
                  { title: "End", field: "end_point" },
                ]
              : [
                  { title: "uId", field: "userId" },
                  { title: "RoadName", field: "roadName" },
                  {
                    title: "VideoDate",
                    field: "videoDate",
                    render: (rowData) => (
                      <>
                        {moment(rowData.videoDate, "YYYY-MM-DD")
                          .local()
                          .format("DD/MM/YYYY")}
                      </>
                    ),
                  },
                  { title: "Distress", field: "distress" },
                  { title: "Latitude", field: "latitude" },
                  { title: "Longitude", field: "longitude" },
                  { title: "Image", field: "picPath" },
                ]
          }
          style={{ height: screenHeight < 700 ? "200px" : "300px" }}
          data={JSON.parse(JSON.stringify(isLineType ? data : attributeData))}
          onRowClick={(evt, selectedRow, togglePanel) => {
            const startPos = isLineType
              ? [
                  selectedRow.polylines[0].latitude,
                  selectedRow.polylines[0].longitude,
                ]
              : [selectedRow.latitude, selectedRow.longitude];
            setSelectedRow(selectedRow);

            showSurveyedDetail(
              startPos,
              selectedRow.videoDate,
              isLineType
                ? selectedRow.polylines
                : data
                ? data.find((x) =>
                    x.polylines.find(
                      (p) =>
                        p.latitude == selectedRow.latitude &&
                        p.longitude == selectedRow.longitude
                    )
                  )?.polylines
                : null
            );
            togglePanel();
          }}
          options={{
            exportButton: HasPermissions(MenuType.GIS, PermissionType.Download),
            exportAllData: true,
            exportCsv: () =>
              exportCsv(
                [
                  { title: "uId", field: "userId" },
                  { title: "RoadName", field: "roadName" },
                  { title: "VideoDate", field: "videoDate" },
                  { title: "Distress", field: "distress" },
                  { title: "Latitude", field: "latitude" },
                  { title: "Longitude", field: "longitude" },
                  { title: "Image", field: "picPath" },
                ],
                attributeData
              ),
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow && selectedRow.tableData.id === rowData.tableData.id
                  ? MAIN_COLOR
                  : "#FFF",
            }),
            pageSize: screenHeight < 700 ? 2 : 5,
          }}
          components={{
            Pagination: (props) => (
              <TablePagination {...props} rowsPerPageOptions={[]} />
            ),
          }}
          detailPanel={(rowData) => {
            return isLineType ? (
              <MaterialTable
                title="Polylines"
                columns={[
                  { title: "uId", field: "userId" },
                  { title: "RoadName", field: "roadName" },
                  {
                    title: "VideoDate",
                    field: "videoDate",
                    render: (rowData) => (
                      <>
                        {moment(rowData.videoDate, "YYYY-MM-DD")
                          .local()
                          .format("DD/MM/YYYY")}
                      </>
                    ),
                  },
                  { title: "Distress", field: "distress" },
                  { title: "Latitude", field: "latitude" },
                  { title: "Longitude", field: "longitude" },
                  { title: "Image", field: "picPath" },
                ]}
                style={{ height: "380px" }}
                data={JSON.parse(JSON.stringify(rowData.polylines))}
                options={{
                  search: false,
                  paging: false,
                }}
                onRowClick={(evt, selectedRow) => {
                  showSurveyedDetail(
                    [selectedRow.latitude, selectedRow.longitude],
                    selectedRow.videoDate
                  );
                }}
              />
            ) : null;
          }}
        />
      )}
    </div>
  );
};

export default compose(
  withState("roadFile", "setRoadFile", null),
  withState("selectedRow", "setSelectedRow", null),
  connect(
    (state) => ({
      roadFiles: state.road.roadFiles,
      selectedRoad: state.road.selectedRoad,
      selectedTab: state.road.selectedTab,
      renderRoadInMap: state.road.renderRoadInMap,
      showAttributeList: state.road.showAttributeList,
      attributeData: state.road.attributeData,
      surveyedAllocated: state.road.surveyedAllocated,
      surveyedMapType: state.gis.surveyedMapType,
      rankFilters: state.road.rankFilters,
    }),
    (dispatch) => ({})
  )
)(AttributeList);
