import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { HasPermissions } from './objectExtensions';

export default function secureAuth(ComponentToProtect, menuType, permissionType) {
  return connect(
    state => ({ isAdmin: state.auth.isAdmin }),
    () => ({}))(
      class extends Component {
        constructor() {
          super();
          this.state = {
            loading: true,
            redirect: false,
          };
        }
        componentDidMount() {
          //   fetch('/checkToken')
          //     .then(res => {
          //       if (res.status === 200) {
          //         this.setState({ loading: false });
          //       } else {
          //         const error = new Error(res.error);
          //         throw error;
          //       }
          //     })
          //     .catch(err => {
          //       console.error(err);
          //       this.setState({ loading: false, redirect: true });
          //     });
          if (sessionStorage.getItem('userLogged')) {
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false, redirect: true });
          }
        }
        render() {
          const { loading, redirect } = this.state;
          if (loading) {
            return null;
          }
          if (redirect) {
            return <Redirect to="/login" />;
          }
          if (!this.props.isAdmin && this.props.location.pathname === "/road") {
            return <Redirect to="/" />;
          }
          if (!HasPermissions(menuType, permissionType)) {
            return <Redirect to="/permission" />
          }
          return (
            <React.Fragment>
              <ComponentToProtect {...this.props} />
            </React.Fragment>
          );
        }
      })
}