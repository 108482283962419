function processResponse(response) {
  return new Promise((resolve, reject) => {
    let cresponse = response.clone();
    response
      .json()
      .then((data) => {
        return response.ok
          ? resolve(data)
          : reject({
              statusCode: response.status,
              statusText: response.statusText,
              message: data?.detail || data,
            });
      })
      .catch((err) => {
        if (
          err.toString().includes("Failed to execute 'json'") ||
          err.toString().includes("Unexpected token U in JSON") ||
          err.toString().includes("Unexpected token S in JSON")
        ) {
          cresponse
            .text()
            .then((msgBody) =>
              response.ok
                ? resolve()
                : reject({
                    statusCode: response.status,
                    statusText: response.statusText,
                    message: msgBody,
                  })
            )
            .catch((errBody) =>
              response.ok
                ? resolve()
                : reject({
                    statusCode: response.status,
                    statusText: response.statusText,
                    message: errBody,
                  })
            );
        } else {
          return response.ok
            ? resolve()
            : reject({
                statusCode: response.status,
                statusText: response.statusText,
                message: err,
              });
        }
      });
  });
}

export const baseFetch = (url, options = {}) =>
  fetch(url, {
    ...options,
    headers: {
      ...(!options.headers
        ? { Accept: "application/json", "Content-Type": "application/json" }
        : {}),
      ...options.headers,
      "Access-Control-Allow-Origin": "*",
    },
  }).then(processResponse);

export const baseFileFetch = (url, options = {}) =>
  fetch(url, {
    ...options,
    headers: {
      ...(!options.headers
        ? { Accept: "application/json", "Content-Type": "application/json" }
        : {}),
      ...options.headers,
      "Access-Control-Allow-Origin": "*",
    },
  });

export const authFetch = (url, options = {}) =>
  baseFetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("userLogged")
          ? JSON.parse(sessionStorage.getItem("userLogged")).token
          : ""
      }`,
      ...options.headers,
      "Access-Control-Allow-Origin": "*",
    },
  });

export const authFileFetch = (url, options = {}) =>
  baseFileFetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("userLogged")
          ? JSON.parse(sessionStorage.getItem("userLogged")).token
          : ""
      }`,
      ...options.headers,
      "Access-Control-Allow-Origin": "*",
    },
  });
