export const barChartOptions = {
  responsive: true,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.5,
        maxBarThickness: 20,
        minBarLength: 2,
        gridLines: {
          color: "#595959",
          drawTicks: false
        },
        ticks: {
          padding: 16,
          fontColor: "white",
          maxRotation: 90,
          minRotation: 50
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: "white",
        },
        gridLines: {
          color: "#595959",
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: false
    }
  }
};

export const barChartData = {
  labels: [
    "2018",
    "2019"
  ],
  datasets: [
    {
      data: [
        0,
        0,
      ],
      backgroundColor: "#4c6164",
      borderColor: "#8ab6c1",
      borderWidth: 1
    }
  ]
};
