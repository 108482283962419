import React from 'react'
import { Button as MaterialButton, CircularProgress } from '@material-ui/core'

import './Button.scss'

const Button = ({
  disabled,
  onClick,
  title,
  loading
}) => {
  return (
    <div className={`pms-button ${loading && 'loading'}`}>
      <MaterialButton disabled={disabled} onClick={() => onClick()}>{title}</MaterialButton>
      {loading && <CircularProgress size={24} className="loading-indicator" />}
    </div>
  )
}

export default Button
