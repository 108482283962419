import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Button,
  Paper,
  IconButton,
  InputBase,
  Box,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import {
  CloudUpload as UploadIcon,
  Close as DeleteIcon,
} from "@material-ui/icons";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { isArray } from "lodash";
import { UploadSurveyedData } from "../../../services/actions/RoadAction";
import { Trans, withTranslation } from "react-i18next";
import i18n from "../../../localization/i18n";
import { HasPermissions } from "../../../shared/utils/objectExtensions";
import { MenuType, PermissionType } from "../../../shared/utils/constant";
import FieldsMappingDialog from "./FieldsMappingDialog";
import ImageUploadDialog from "./ImageUploadDialog";

const UploadForm = ({
  uploading,
  dataFile,
  setDataFile,
  imageFiles,
  setImageFiles,
  deleteImageFile,
  deleteSurveyFile,
  openFieldMapping,
  setOpenFieldMapping,
  userId,
  userName,
  userFilter,
}) => {
  return HasPermissions(MenuType.GIS, PermissionType.Upload) ? (
    <Card className="upload-container">
      <CardHeader
        avatar={<CloudUpload />}
        title={<Trans>road_uploader</Trans>}
        className="upload-header"
      />
      <CardContent className="upload-form">
        {uploading ? (
          <CircularProgress />
        ) : (
          <>
            {imageFiles?.length ? (
              <></>
            ) : (
              <FileInput
                id="data"
                label={<Trans>label_data</Trans>}
                tooltip={i18n.t("tooltip_select_csv_file")}
                placeholder={i18n.t("tooltip_select_csv_file")}
                value={dataFile}
                multiple={true}
                fileOptions={{ accept: ".csv" }}
                onChange={(files) => {
                  if (Object.keys(files).length) {
                    const newFiles = [
                      ...(dataFile || []),
                      ...Object.keys(files)
                        .map((key) => files[key])
                        .filter(
                          (file) =>
                            file.name.includes("csv") &&
                            !(dataFile || []).some((x) => x.name === file.name)
                        ),
                    ];
                    setDataFile(newFiles);
                    if (newFiles.length > 1) {
                      setImageFiles(null);
                    }
                  }
                }}
                deleteItem={deleteSurveyFile}
                removeAll={() => setDataFile(null)}
              />
            )}
            {dataFile?.length ? (
              <></>
            ) : (
              <FileInput
                id="images"
                label={<Trans>label_images</Trans>}
                tooltip={i18n.t("tooltip_select_folder_image")}
                placeholder={i18n.t("tooltip_select_folder_image")}
                value={imageFiles}
                multiple={true}
                fileOptions={{
                  directory: "",
                  webkitdirectory: "",
                  mozdirectory: "",
                }}
                onChange={(files) =>
                  Object.keys(files).length &&
                  setImageFiles(
                    Object.keys(files)
                      .map((key) => files[key])
                      .filter(
                        (file) =>
                          file.type.includes("image") &&
                          !(imageFiles || []).some((x) => x.name === file.name)
                      )
                  )
                }
                deleteItem={deleteImageFile}
                removeAll={() => setImageFiles(null)}
              />
            )}
            <Box className="form-action">
              <Button
                disabled={!dataFile && !imageFiles}
                onClick={() => setOpenFieldMapping(true)}
                color="primary"
                variant="contained"
              >
                <Trans>upload</Trans>
              </Button>
            </Box>
          </>
        )}
      </CardContent>
      <FieldsMappingDialog
        handleClose={() => setOpenFieldMapping(false)}
        open={dataFile?.length && openFieldMapping}
        files={dataFile}
        saveSuccess={() => {
          setDataFile(null);
          setOpenFieldMapping(false);
        }}
        userId={userId || userFilter}
        userName={userName}
      />
      <ImageUploadDialog
        handleClose={() => setOpenFieldMapping(false)}
        open={imageFiles?.length && openFieldMapping}
        files={imageFiles}
        saveSuccess={() => {
          setImageFiles(null);
          setOpenFieldMapping(false);
        }}
        userId={userId || userFilter}
        userName={userName}
      />
    </Card>
  ) : (
    <></>
  );
};

const FileInput = ({
  id,
  label,
  tooltip,
  placeholder,
  multiple,
  fileOptions,
  value,
  onChange,
  deleteItem,
  removeAll,
}) => {
  return (
    <>
      <Paper className="file-input-container">
        <div className="input-label" title={tooltip}>
          {label}
        </div>
        <InputBase
          className="input"
          value={
            isArray(value) ? value.map((x) => x.name).join(";") : value || ""
          }
          placeholder={placeholder}
          disabled
        />
        <InputBase
          id={id}
          className="input-file"
          placeholder={placeholder}
          type="file"
          inputProps={{
            multiple: multiple,
            ...fileOptions,
          }}
          onChange={(event) => onChange(event.target.files)}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <label htmlFor={id}>
          <IconButton
            className="icon-button"
            aria-label="upload picture"
            component="span"
          >
            <UploadIcon />
          </IconButton>
        </label>
      </Paper>
      {multiple && value && value.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h3 style={{ margin: 8 }}>Items</h3>
            <IconButton
              style={{
                color: "lightcoral",
                padding: 0,
              }}
              component="span"
              onClick={() => removeAll()}
              title="Remove all"
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div className="list-files">
            {value.map((x, idx) => (
              <div className="file-name" key={idx}>
                {x.name}
                <IconButton
                  className="delete-button"
                  component="span"
                  onClick={() => deleteItem(x.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default compose(
  withTranslation("translations"),
  withState("dataFile", "setDataFile", null),
  withState("imageFiles", "setImageFiles", null),
  withState("submitting", "setSubmitting", false),
  withState("openFieldMapping", "setOpenFieldMapping", false),
  connect(
    (state) => ({
      uploading: state.road.uploading,
      userFilter: state.road.userFilter,
    }),
    (dispatch) => ({
      UploadSurveyedData: (dataFile, imageFiles) =>
        dispatch(UploadSurveyedData(dataFile, imageFiles)),
    })
  ),
  withHandlers({
    deleteImageFile:
      ({ imageFiles, setImageFiles }) =>
      (name) => {
        setImageFiles(imageFiles.filter((x) => x.name !== name));
      },
    deleteSurveyFile:
      ({ dataFile, setDataFile }) =>
      (name) => {
        setDataFile(dataFile.filter((x) => x.name !== name));
      },
  }),
  lifecycle({
    componentDidUpdate() {
      if (this.props.uploading && !this.props.submitting) {
        this.props.setSubmitting(true);
      }
      if (!this.props.uploading && this.props.submitting) {
        this.props.setDataFile(null);
        this.props.setImageFiles(null);
        this.props.setSubmitting(false);
      }
    },
  })
)(UploadForm);
