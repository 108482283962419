import React from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
  TextField,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { Pie } from "react-chartjs-2";
import { compose, withState, lifecycle } from "recompose";
import moment from "moment";
import { get } from "lodash";
import { GetSurveyedDetail } from "../../../services/actions/GisAction";
import { pieChartOptions } from "../../DashBoard/pie.data";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Lightbox from "react-image-lightbox";
import { HasPermissions } from "../../../shared/utils/objectExtensions";
import EventNoteIcon from "@material-ui/icons/EventNote";
import MapIcon from "@material-ui/icons/Map";
import {
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../../shared/utils/constant";
import SurveyedDetailOld from "./SurveyedDetail_Old";

const SurveyedDetail = (props) => {
  const {
    loading,
    selectedTab,
    setSelectedTab,
    surveyedDetailData,
    startPosition,
    startDate,
    showSurveyedDetail,
    surveyedData,
    roadListOpen,
    setRoadListOpen,
    surveyedMapType,
    previewImageOpen,
    setPreviewImageOpen,
    surfaces,
    polylines,
    lastPositionData,
    transitionObject,
    rankFilters,
    yearFilter,
    users,
    userFilter,
  } = props;
  const roadName = get(surveyedDetailData, "surveyedData.roadName");
  const dataSummary = get(
    surveyedDetailData,
    "deteriorationRateReport.summaryChart",
    {}
  );
  const isMapTypeLine = surveyedMapType === "line";
  const hasDp = get(surveyedDetailData, "surveyedData.surveyType") !== 0;
  const currentUser =
    users?.find((x) => x.id == userFilter) ||
    JSON.parse(sessionStorage.getItem("userLogged"));
  const isOldUser = currentUser?.isOldUser ? true : false;

  return isOldUser ? (
    <SurveyedDetailOld {...props} />
  ) : (
    <div className={`summary-card`}>
      {isMapTypeLine && (
        <AppBar position="static">
          <Tabs
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
            aria-label="simple tabs example"
            className="tabs-container"
          >
            <Tab label="Image" style={{ width: "50%" }} />
            <Tab label="Data Summary" style={{ width: "50%" }} />
          </Tabs>
        </AppBar>
      )}
      <TabPanel
        className={`tab-content ${!isMapTypeLine ? "point-type " : ""}  ${
          hasDp ? "has-dp" : ""
        }`}
        value={selectedTab}
        index={0}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ImageContent
            hasDp={hasDp}
            loading={loading}
            data={surveyedDetailData}
            dataSummary={dataSummary}
            currentPosition={startPosition}
            surveyedDate={startDate}
            dates={get(surveyedDetailData, "surveyedDates", []).map((x) => ({
              title: moment(x, "YYYY-MM-DD").format("MMM-DD-YYYY"),
              value: x,
            }))}
            showSurveyedDetail={showSurveyedDetail}
            surveyedData={surveyedData}
            isMapTypeLine={isMapTypeLine}
            roadName={roadName}
            roadListOpen={roadListOpen}
            setRoadListOpen={setRoadListOpen}
            previewImageOpen={previewImageOpen}
            setPreviewImageOpen={setPreviewImageOpen}
            surfaces={surfaces}
            polylines={polylines}
            lastPositionData={lastPositionData}
            transitionObject={transitionObject}
            rankFilters={rankFilters}
            yearFilter={yearFilter}
          />
        )}
      </TabPanel>
      {isMapTypeLine && (
        <TabPanel className="tab-chart" value={selectedTab} index={1}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SummaryContent
              data={dataSummary}
              roadName={roadName}
              surveyedDate={startDate}
              showSurveyedDetail={showSurveyedDetail}
              roadListOpen={roadListOpen}
              setRoadListOpen={setRoadListOpen}
              surfaces={surfaces}
              polylines={polylines}
              surveyedData={surveyedData}
            />
          )}
        </TabPanel>
      )}
    </div>
  );
};

const ImageContent = ({
  data,
  dataSummary,
  currentPosition,
  dates,
  surveyedDate,
  showSurveyedDetail,
  surveyedData,
  isMapTypeLine,
  roadName,
  roadListOpen,
  setRoadListOpen,
  previewImageOpen,
  setPreviewImageOpen,
  surfaces,
  polylines,
  lastPositionData,
  transitionObject,
  rankFilters,
  hasDp,
  yearFilter,
}) => {
  const [showImgDetected, setShowImgDetected] = React.useState(true);
  const picPath = get(
    data,
    `surveyedData.${showImgDetected ? "picPath_Detected" : "picPath"}`
  );
  const currentPoint = [
    get(data, "surveyedData.latitude", null),
    get(data, "surveyedData.longitude", null),
  ];
  const transitionPoints =
    currentPoint[0] && polylines && transitionObject
      ? transitionObject
      : {
          prev: get(data, "prevPosition", null),
          next: get(data, "nextPosition", null),
          polylines: polylines || [],
        };
  const showSwitchImage = HasPermissions(
    MenuType.GIS,
    PermissionType.SwitchImages
  );

  let prevPos = transitionPoints.prev;
  let nextPos = transitionPoints.next;
  // if (!isMapTypeLine && rankFilters && rankFilters.length) {
  //   let prevPoint = null;
  //   let nextPoint = null;
  //   let hasPrevPoint = false;
  //   let shouldBreak = false;
  //   for (let i = 0; i < surveyedData.length; i++) {
  //     const surveyItem = surveyedData[i];
  //     for (let h = 0; h < surveyItem.polylines.length; h++) {
  //       const point = surveyItem.polylines[h];
  //       const pointDistress = surveyItem.polylineDistress.find(
  //         (d) => d.polylines === JSON.stringify(point)
  //       );
  //       if (
  //         rankFilters.find((r) => {
  //           if (r.hasPredict && r.rangeValue) {
  //             var values = JSON.parse(r.rangeValue);
  //             return (
  //               values[0] <= pointDistress?.distressAverage &&
  //               pointDistress?.distressAverage <= values[1]
  //             );
  //           } else {
  //             return r.value == pointDistress?.distressAverage;
  //           }
  //         })
  //       ) {
  //         if (
  //           hasPrevPoint &&
  //           JSON.stringify(point) != JSON.stringify(currentPoint)
  //         ) {
  //           nextPoint = point;
  //           shouldBreak = true;
  //           break;
  //         } else if (JSON.stringify(point) != JSON.stringify(currentPoint)) {
  //           prevPoint = point;
  //         } else {
  //           hasPrevPoint = true;
  //         }
  //       }
  //     }
  //     if (shouldBreak) break;
  //   }

  //   prevPos = prevPoint;
  //   nextPos = nextPoint;
  // }
  const newPolylines = transitionPoints.polylines;

  const roadNames = get(dataSummary, "roadNames", []).filter(
    (x) => x !== roadName
  );
  const roadStartPos = get(dataSummary, "roadStartPos");
  const currentSurface = surfaces
    .map((x) => {
      const distress = Math.round(get(data, "surveyedData.distress", -1));
      if (x.hasPredict) {
        if (x.rangeValue) {
          var xRange = JSON.parse(x.rangeValue);

          if (xRange[0] <= distress && distress <= xRange[1]) {
            return x;
          }
        }
      } else if (x.value == distress) {
        return x;
      }

      return null;
    })
    .find((x) => x) || { color: MAIN_COLOR, title: "N/A" };

  return (
    <>
      {!isMapTypeLine && roadNames && roadNames.length > 0 && (
        <div className="route-selection-dropdown">
          ROUTE:
          <Autocomplete
            options={roadNames}
            disabled={roadNames.length === 0}
            style={{ width: 200, height: 30 }}
            getOptionLabel={(option) => option}
            onOpen={() => setRoadListOpen(true)}
            onClose={() => setRoadListOpen(false)}
            onChange={(_e, value) => {
              const startPos = roadStartPos[value];
              if (startPos)
                showSurveyedDetail(
                  [startPos[0], startPos[1]],
                  surveyedDate,
                  newPolylines,
                  {
                    current: currentPoint,
                    prevPos,
                    nextPos,
                  }
                );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={!roadListOpen && roadName}
                variant="standard"
                fullWidth
                style={{ color: "white" }}
              />
            )}
          />
        </div>
      )}
      {showSwitchImage ? (
        <div>
          <Switch
            color="primary"
            checked={showImgDetected}
            style={{ width: "auto!important" }}
            onChange={(e) => setShowImgDetected(e.target.checked)}
            name="showDetected"
          />
        </div>
      ) : (
        <></>
      )}
      <Carousel
        selectedItem={
          !prevPos || !CheckNextPointInSurveyedData(surveyedData, prevPos)
            ? 0
            : 1
        }
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        width="100%"
        className="tab-content__carousel"
        onChange={(index) => {
          if (
            index === 0 &&
            prevPos &&
            CheckNextPointInSurveyedData(surveyedData, prevPos)
          )
            showSurveyedDetail(
              [prevPos[0], prevPos[1]],
              surveyedDate,
              newPolylines,
              {
                current: currentPoint,
                prevPos,
                nextPos,
                action: "prev",
              }
            );
          else if (
            nextPos &&
            CheckNextPointInSurveyedData(surveyedData, nextPos)
          )
            showSurveyedDetail(
              [nextPos[0], nextPos[1]],
              surveyedDate,
              newPolylines,
              {
                current: currentPoint,
                prevPos,
                nextPos,
                action: "next",
              }
            );
        }}
        onClickItem={(idx, item) =>
          setPreviewImageOpen(item.props.children.props.src)
        }
      >
        {(!prevPos && !nextPos
          ? [picPath]
          : !prevPos ||
            !nextPos ||
            !CheckNextPointInSurveyedData(surveyedData, prevPos) ||
            !CheckNextPointInSurveyedData(surveyedData, nextPos)
          ? [picPath, picPath]
          : [picPath, picPath, picPath]
        ).map((x, idx) => (
          <div key={idx}>
            <img
              alt={idx}
              width={isMapTypeLine ? 400 : 600}
              height={isMapTypeLine ? 200 : 300}
              src={x}
            />
          </div>
        ))}
      </Carousel>
      {previewImageOpen && (
        <Lightbox
          mainSrc={picPath}
          nextSrc={picPath}
          prevSrc={picPath}
          onMoveNextRequest={() => {
            if (nextPos && CheckNextPointInSurveyedData(surveyedData, nextPos))
              showSurveyedDetail(
                [nextPos[0], nextPos[1]],
                surveyedDate,
                newPolylines,
                {
                  current: currentPoint,
                  prevPos,
                  nextPos,
                  action: "next",
                }
              );
          }}
          onMovePrevRequest={() => {
            if (prevPos && CheckNextPointInSurveyedData(surveyedData, prevPos))
              showSurveyedDetail(
                [prevPos[0], prevPos[1]],
                surveyedDate,
                newPolylines,
                {
                  current: currentPoint,
                  prevPos,
                  nextPos,
                  action: "prev",
                }
              );
          }}
          onCloseRequest={() => setPreviewImageOpen(false)}
        />
      )}

      <div className="tab-content__title">
        {hasDp ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              paddingTop: showSwitchImage ? "0" : "30px",
              paddingBottom: "4px",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                width: "110px",
                height: "110px",
                color: "white",
                fontWeight: "bold",
                backgroundColor: currentSurface?.color,
              }}
            >
              <Tooltip title={currentSurface?.title} placement="top">
                <Typography
                  variant="h5"
                  style={{
                    fontSize: "20px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    padding: "4px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {currentSurface?.title}
                </Typography>
              </Tooltip>
              <Typography variant="h2" style={{ fontWeight: "bold" }}>
                {Math.round(get(data, "surveyedData.distress"))}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EventNoteIcon fontSize="large"></EventNoteIcon>
                <Typography component="span">
                  {yearFilter && Number(yearFilter) > moment().year()
                    ? `Prediction after ${
                        Number(yearFilter) - moment().year()
                      } years`
                    : surveyedDate
                    ? surveyedDate.replaceAll("T00:00:00Z", "")
                    : "N/A"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MapIcon fontSize="large"></MapIcon>{" "}
                <Typography component="span">
                  {get(data, "surveyedData.roadName")}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const SummaryContent = ({
  data,
  surveyedDate,
  showSurveyedDetail,
  roadName,
  roadListOpen,
  setRoadListOpen,
  surfaces,
  polylines,
}) => {
  const roadNames = get(data, "roadNames", []).filter((x) => x !== roadName);
  const roadStartPos = get(data, "roadStartPos");
  const labels = surfaces.map(
    (x) =>
      `${
        x.hasPredict
          ? (x.rangeValue || "")
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll(",", "-")
          : x.value
      } ${x.title}`
  );
  return (
    <div className="tab-chart__pie">
      {data && (
        <>
          {(roadName || (roadNames && roadNames.length > 0)) && (
            <div className="route-selection-dropdown">
              ROUTE:
              <Autocomplete
                options={roadNames}
                disabled={roadNames.length === 0}
                style={{ width: 200, height: 30 }}
                getOptionLabel={(option) => option}
                onOpen={() => setRoadListOpen(true)}
                onClose={() => setRoadListOpen(false)}
                onChange={(_e, value) => {
                  const startPos = roadStartPos[value];
                  if (startPos)
                    showSurveyedDetail(
                      [startPos[0], startPos[1]],
                      surveyedDate,
                      polylines
                    );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!roadListOpen && roadName}
                    variant="standard"
                    fullWidth
                    style={{ color: "white" }}
                  />
                )}
              />
            </div>
          )}
          <Pie
            data={{
              labels: labels,
              datasets: [
                {
                  data: data.data,
                  backgroundColor: surfaces ? surfaces.map((x) => x.color) : [],
                },
              ],
            }}
            options={pieChartOptions}
          />
        </>
      )}
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const CheckNextPointInSurveyedData = (surveyedData, point) =>
  surveyedData &&
  surveyedData.find((x) =>
    x.polylines.find((p) => p[0] === point[0] && p[1] === point[1])
  );

export default compose(
  withState("selectedTab", "setSelectedTab", 0),
  withState("dataByDate", "setDataByDate", null),
  withState("dataByPosition", "setDataByPosition", null),
  withState("roadListOpen", "setRoadListOpen", false),
  withState("previewImageOpen", "setPreviewImageOpen", false),
  connect(
    (state) => ({
      loading: state.gis.loading,
      surveyedDetailData: state.gis.surveyedDetailData,
      surveyedMapType: state.gis.surveyedMapType,
      surfaces: state.surface.surfaces,
      rankFilters: state.road.rankFilters,
      yearFilter: state.road.yearFilter,
      users: state.user.users,
      userFilter: state.road.userFilter,
    }),
    (dispatch) => ({
      getData: (date, pos) => dispatch(GetSurveyedDetail(date, pos)),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.getData(this.props.startDate, this.props.startPosition);
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.startDate !== prevProps.startDate ||
        this.props.startPosition !== prevProps.startPosition
      ) {
        this.props.getData(this.props.startDate, this.props.startPosition);
      } else if (
        this.props.yearFilter !== prevProps.yearFilter &&
        this.props.startDate &&
        this.props.startPosition
      ) {
        this.props.getData(this.props.startDate, this.props.startPosition);
      }
    },
  })
)(SurveyedDetail);
