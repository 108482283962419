import React from 'react';
import { Card, CardHeader, CardContent, CardActions, Button, Popover, CircularProgress } from '@material-ui/core';

import { CloudUpload } from '@material-ui/icons';
import { compose, withState } from 'recompose';
import './UploadButton.scss';

const Upload = ({ title, placeholder, uploadFile, setUploadFile, handleUploadFile, uploading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button variant="contained" className="upload-btn" onClick={handleClick}>
        <CloudUpload />  {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className="upload-container">
          <CardHeader
            avatar={<CloudUpload />}
            title="Road uploader"
            className="upload-header"
          />
          <CardContent className="upload-form">
            {uploading
              ? <CircularProgress />
              : <form method="post" action="#" id="#">
                <div className="form-group files">
                  <label>{placeholder} </label>
                  <input type="file" className="form-control" multiple="" onChange={event => setUploadFile(event.target.files[0])} />
                </div>
              </form>}
          </CardContent>
          <CardActions disableSpacing className="form-action">
            <Button onClick={() => handleUploadFile(uploadFile)}>Upload</Button>
          </CardActions>
        </Card>
      </Popover>
    </>

  )
}

export default compose(withState('uploadFile', 'setUploadFile', null))(Upload)