export default {
  app_title: "SMART PAVEMENT MANAGEMENT SYSTEM",
  profile: "Profile",
  logout: "Log out",
  user_login: "User Log-In",
  user_id: "User Id",
  user_password: "Password",
  login: "Login",
  dashboard: "Dashboard",
  gis: "GIS",
  road_surveyed_list: "Road Surveyed List",
  road_information_list: "Road Information List",
  dashboard_total_length: "Total length (Very Poor)",
  dashboard_mean_pavement_distress: "Mean pavement distress%",
  dashboard_pavement_distress_map: "Pavement distress map",
  dashboard_pavement_distress_distribution: "Pavement distress distribution",
  no_data: "There is no data",
  date: "Date",
  user: "User",
  clear_filter: "Clear Filter",
  has_data: "Has data",
  invalid_date: "Invalid Date",
  show: "Show",
  hide: "Hide",
  attributes: "Attributes",
  deterioration_rank: "Surface Condition",
  road_uploader: "Road uploader",
  label_data: "Data",
  label_images: "Images",
  tooltip_select_csv_file: "Select csv files",
  tooltip_select_folder_image: "Select the image folder",
  upload: "Upload",
  list_images: "List imagess",
  label_upload_surveyed: "Upload surveyed",
  label_upload_surveyed_placeholder: "Upload *.csv file",
  label_upload_road_info: "Upload road information",
  label_upload_road_info_placeholder: "Upload *geo.json file",
  table_title_filename: "FileName",
  table_title_owner: "Owner",
  table_title_created_date: "Created Date",
  table_title_created_number_point: "Number of point",
  table_title_type: "Type",
  table_title_number_road: "Number of road",
  table_action: "Action",
  table_detail: "Detail",
  label_geo_file: "Geo files",
  label_manage_user: "Manage Users",
  label_surveyed_file: "Surveyed files",
  label_login_logs: "Logs",
  manage_users: "Manage Users",
  first_name: "First name",
  last_name: "Last name",
  username: "Username",
  email: "Email",
  add: "Add new",
  save: "Save",
  cancel: "Cancel",
  back_to_list: "Back",
  permission_required: "PERMISSION REQUIRED!!!",
  permission_required_text: "Please contact to administrator to get access",
  reset_checked_points: "Reset checked points",
  download_shapefile: "Download shapefile",
  manage_surface_condition: "Surface Condition",
  settings: "Settings",
  title: "Title",
  value: "Value",
  color: "Color",
  cost: "Cost",
  description: "Description",
  fullname: "Fullname",
  bucket_name: "Bucket Name",
};
