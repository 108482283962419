export default {
    "app_title": "スマート舗装管理システム",
    "profile": "プロフィール",
    "logout": "ログアウト",
    "user_login": "ユーザーログイン",
    "user_id": "ユーザーID",
    "user_password": "パスワード",
    "login": "ログインする",
    "dashboard": "ダッシュボード",
    "gis": "GIS",
    "road_surveyed_list": "道路調査リスト",
    "road_information_list": "道路情報リスト",
    "dashboard_total_length": "全長（非常に悪い）",
    "dashboard_mean_pavement_distress": "平均舗装の苦痛％",
    "dashboard_pavement_distress_map": "舗装遭難マップ",
    "dashboard_pavement_distress_distribution": "舗装の苦痛の分布",
    "no_data": "データーがない",
    "date": "日付",
    "user": "ユーザー",
    "clear_filter": "フィルターをクリア",
    "has_data": "データあり",
    "invalid_date": "無効な日付",
    "show": "公演",
    "hide": "隠す",
    "attributes": "属性",
    "deterioration_rank": "劣化ランク",
    "road_uploader": "ロードアップローダー",
    "label_data": "データ",
    "label_images": "画像",
    "tooltip_select_csv_file": "csvファイルを選択します",
    "tooltip_select_folder_image": "画像フォルダを選択します",
    "upload": "アップロードする",
    "list_images": "リスト画像",
    "label_upload_surveyed": "調査済みのアップロード",
    "label_upload_surveyed_placeholder": "* .csvファイルをアップロード",
    "label_upload_road_info": "道路情報をアップロードする",
    "label_upload_road_info_placeholder": "* geo.jsonファイルをアップロードします",
    "table_title_filename": "ファイル名",
    "table_title_owner": "オーナー",
    "table_title_created_date": "作成日",
    "table_title_created_number_point": "ポイント数",
    "table_title_type": "タイプ",
    "table_title_number_road": "道路の数",
    "label_geo_file": "ジオファイル",
    "label_surveyed_file": "調査したファイル"
  }